import { faFile, faFileImage, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArchiveIcon from '@mui/icons-material/Archive';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DownArrow from '@mui/icons-material/ArrowDropDown';
import UpArrow from '@mui/icons-material/ArrowDropUp';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import TrashIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import {
  Button, Card,
  CardContent, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup,
  FormHelperText,
  Grid, IconButton, Input, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import styles from './style';
import './style.css';

var AWS = require('aws-sdk');

var s3 = new AWS.S3({
  accessKeyId: 'AKIARE5YHMQISYBZVW7L',
  secretAccessKey: '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',
  region: 'us-east-1'
});


const invertSort = {
  asc: "desc",
  desc: "asc"
}

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class Activities extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      route: 'ActivitiesScreen',
      refreshing: false,
      isLoading: true,
      dataSource: [],
      activitys: [],
      height: 100,
      rowsPerPage: 25,
      currentPage: 0,
      isNewOpen: false,
      isStudentOpen: false,
      isDeleteOpen: false,
      isDeleteDocOpen: false,
      isAttachmentOpen: false,
      selectedItem: {},
      consultants: [],
      targets: [],
      schools: [],
      services: [],
      consultant_id: 0,
      consultantValid: true,
      co_consultant_id: 0,
      school_id: 0,
      schoolValid: true,
      service_id: 0,
      hours: 0,
      hoursValid: true,
      target_id: 0,
      targetValid: true,
      number_of_parents: 0,
      numberOfParentsValid: true,
      date: new Date(),
      dateValid: true,
      description: '',
      descriptionValid: true,
      activity_id: 0,
      categories: [],
      students: [],
      search: '',
      choices: [],
      serviceChoices: [],
      serviceChoicesValid: true,
      categoryChoices: [],
      filteredActivities: [],
      dataSource2: [],
      selectedYear: '2019-2020',
      file: "",
      recentActivityId: 0,
      documents: [],
      width: 800,
      studentSearch: '',
      sortBy: '',
      sortDirection: 'desc'
    }

    this.onSearch = this.onSearch.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.getData();

    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    let update_height = window.innerHeight - 350;
    this.setState({ height: update_height });
  }

  getData() {

    fetch(process.env.REACT_APP_API_URL + '/activities/user/' + this.props.userId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.authenticated !== false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.activities,
            filteredActivities: responseJson.activities
          });
        } else {
          //this.props.onLogout();
        }
      })

      .catch((error) => {
        this.setState({
          isLoading: false,
          dataSource: []
        })
        //this.props.onLogout();
      });


    fetch(process.env.REACT_APP_API_URL + '/consultants', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          consultants: responseJson.consultants
        });
      })

    fetch(process.env.REACT_APP_API_URL + '/targets', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          targets: responseJson.targets
        });

      })
      .catch((error) => {

      });

    fetch(process.env.REACT_APP_API_URL + '/schools', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          schools: responseJson.schools
        });
      })
      .catch((error) => {

      });

    fetch(process.env.REACT_APP_API_URL + '/services', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          services: responseJson.services
        });

        var serviceChoices = [];

        if (responseJson.services !== undefined) {
          for (let i = 0; i < responseJson.services.length; i++) {
            serviceChoices['choice_' + responseJson.services[i].service_id] = 0;
          }
        }

        this.setState({
          serviceChoices: serviceChoices
        })
      })
      .catch((error) => {

      });

  }

  onSearch = (searchText) => {

    const newData = this.state.dataSource.filter(function (item) {
      const nameData = item.first_name.toUpperCase() + " " + item.last_name.toUpperCase()
      const itemData = item.description.toUpperCase();
      const textData = searchText.toUpperCase()

      if (textData !== "") {
        return itemData.indexOf(textData) > -1 || nameData.indexOf(textData) > -1
      } else return true;

    })
    this.setState({
      filteredActivities: newData,
      searchText: searchText
    })
  }

  handleChangePage(event, newPage) {
    this.setState({
      currentPage: newPage
    })
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: event.target.value
    })
  }

  handleSort = (col) => {
    this.setState(state => ({
      sortBy: col,
      sortDirection: state.sortBy === col ? invertSort[state.sortDirection] : 'asc'
    }));
  }

  renderDocuments() {
    if (this.state.documents.length === 0) {
      return (
        <Grid style={{ height: '100%', width: '100%', justifyContent: 'center', marginBottom: 210, textAlign: 'center' }}>
          <FontAwesomeIcon icon={faFile} style={{ fontSize: 125, marginTop: '10%', color: '#5F1315' }} />
          <Typography style={{ fontSize: 22, marginTop: 20, fontFamily: 'Josefin Sans' }}>Please drag your file into this window.</Typography>
        </Grid>)
    }

    if (this.state.documents !== undefined) {

      var content = this.state.documents.map((item, key) => {


        if (item.content_type === 'application/pdf') {
          return (
            <Grid key={key} item style={{ height: 150, width: 150, margin: 10, marginBottom: 100 }}>
              <Card raised={this.state['cards_' + key]}
                onMouseOut={() => this.setState({ ['cards_' + key]: false })}
                onMouseOver={() => this.setState({ ['cards_' + key]: true })}
                style={{ cursor: 'pointer' }}>
                <CardContent>
                  <a href={this.state.urls['aws_key_' + item.aws_key]} target="_blank">
                    <Grid container style={{ textAlign: 'center', height: 65, justifyContent: 'center', marginBottom: 10 }}>
                      <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: 75, color: '#5F1315' }} />
                    </Grid>
                    <hr style={{ border: '1px solid #5F1315' }} />
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography style={{ marginTop: 7, fontSize: 12, textOverflow: 'ellipsis' }}>{item.document_name}</Typography>
                      </Grid>
                    </Grid>
                  </a>
                  <Button style={{ marginTop: 0, marginBottom: -10 }}>
                    <TrashIcon style={{ fontSize: 17, }} onClick={() => this.onDeleteDocOpen(item)}>Delete</TrashIcon>
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          )
        }
        else if (item.content_type === 'image/png' || item.content_type === 'image/jpg' ||
          item.content_type === 'image/jpeg' || item.content_type === 'image/gif' || item.content_type === 'image/tiff') {

          var fileExt = item.content_type.split("/");

          return (
            <Grid key={key} item style={{ height: 230, width: 250, margin: 10, marginBottom: 100 }}>
              <Card raised={this.state['cards_' + key]}
                onMouseOut={() => this.setState({ ['cards_' + key]: false })}
                onMouseOver={() => this.setState({ ['cards_' + key]: true })}
                style={{ cursor: 'pointer' }}>
                <CardContent>
                  <a href={this.state.urls['aws_key_' + item.aws_key]} target="_blank">

                    <Grid container style={{ textAlign: 'center', height: 150, justifyContent: 'center', marginBottom: 10 }}>

                      <img style={{ maxHeight: 150, maxWidth: 250 }} alt=""
                        src={this.state.urls['aws_key_' + item.aws_key]} />

                    </Grid>
                    <hr style={{ border: '1px solid #5F1315' }} />
                    <Grid container>
                      <Grid item xs={2}>
                        <FontAwesomeIcon icon={faFileImage} style={{ marginTop: 5, marginLeft: 10, fontSize: 22, color: '#5F1315' }} />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography style={{ marginTop: 7, width: 250, textOverflow: 'ellipsis' }} noWrap={true}>{item.document_name}</Typography>
                      </Grid>
                    </Grid>
                  </a>
                  <Button style={{ marginLeft: 150, marginTop: 40, marginBottom: -10 }}>
                    <TrashIcon style={{ fontSize: 17 }} onClick={() => this.onDeleteDocOpen(item)}>Delete</TrashIcon>
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          )
        } else {
          return (
            <Grid key={key} style={{ height: 230, width: 250, margin: 10, marginBottom: 100 }}>
              <Card raised={this.state['cards_' + key]}
                style={{ marginBottom: 150 }}
                onMouseOut={() => this.setState({ ['cards_' + key]: false })}
                onMouseOver={() => this.setState({ ['cards_' + key]: true })} >
                <CardContent>
                  <Grid item xs={10}>
                    <SentimentVeryDissatisfiedIcon style={{ paddingLeft: '40%', fontSize: 70, textAlign: 'center', paddingTop: 30 }}></SentimentVeryDissatisfiedIcon>
                    <Typography style={{ marginTop: 7, width: 250, textOverflow: 'ellipsis' }} noWrap={true}>This file type is not supported.</Typography>
                  </Grid>
                  <Button style={{ marginLeft: 150, marginTop: 0, marginBottom: -10 }}>
                    <TrashIcon style={{ fontSize: 17, }} onClick={() => this.onDeleteDocOpen(item)}>Delete</TrashIcon>
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          )
        }
      });

      return content;
    }

  }

  getAttachments(item) {
    this.setState({
      isLoading: true,
      isAttachmentOpen: true,
      recentActivityId: item.activity_id,
      selectedItem: item,
      urls: []
    });

    fetch(process.env.REACT_APP_API_URL + '/activity_document/' + item.activity_id, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          documents: responseJson.documents,
          isLoading: false
        });

        let url = [];

        for (var i = 0; i < responseJson.documents.length; i++) {
          var params = {
            Bucket: responseJson.documents[i].aws_bucket,
            Key: responseJson.documents[i].aws_key
          };

          url['aws_key_' + params.Key] = s3.getSignedUrl('getObject', params);
        }

        this.setState({
          urls: url
        });

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        });
      });
  }

  onDrop = (file) => {
    let activityId = this.state.recentActivityId
    let item = this.state.selectedItem

    this.setState({
      isLoading: true
    })

    var props = this.props;
    var scope = this;
    var ext = file[0].path.split('.')[1];
    var random = Math.floor(Math.random() * 900000000000000000);

    var filename = 'activity/document/' + random + '.' + ext;

    var params = {
      Key: filename,
      Body: file[0],
      ContentType: file[0].type,
      Bucket: 'wkgearup.com'
    };

    s3.upload(params, function (err, data) {
      if (err) {
        console.log(err, err.stack);
      }
      else {
        fetch(process.env.REACT_APP_API_URL + '/activities/document', {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'token': props.token
          },
          credentials: 'omit',
          body: JSON.stringify({
            activity_id: activityId,
            document_name: file[0].path,
            aws_bucket: 'wkgearup.com',
            aws_key: filename,
            content_type: file[0].type
          })
        })
          .then((response) => response.json())
          .then((responseJson) => {
            scope.setState({
              isLoading: false
            })
            scope.getAttachments(item);
          })
          .catch((error) => {
            scope.setState({
              isLoading: false
            })
          });
      }
    })
  }

  getStudents(school_id) {

    fetch(process.env.REACT_APP_API_URL + '/students/school/' + school_id, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          dataSource2: responseJson.students
        });

        this.filteredDataSource = responseJson.students;

        var choices = [];

        if (responseJson.students !== undefined) {
          for (let i = 0; i < responseJson.students.length; i++) {
            choices['choice_' + responseJson.students[i].student_id] = 0;
          }
        }

        this.setState({
          choices: choices
        })

      })
      .catch((error) => {

      });
  }


  getCategories(school_id) {

    fetch(process.env.REACT_APP_API_URL + '/categories/school/' + school_id, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {

          this.setState({
            isLoading: false,
            categories: responseJson.results
          });
        }

        var categoryChoices = [];

        if (responseJson.results !== undefined) {
          for (let i = 0; i < responseJson.results.length; i++) {
            categoryChoices['choice_' + responseJson.results[i].category_id] = 0;
          }
        }

        this.setState({
          categoryChoices: categoryChoices
        })

      })
      .catch((error) => {

      });

  }

  updateSearch = (e) => {
    this.setState({
      search: e.target.value.toUpperCase()
    })
  }

  updateStudentSearch = (e) => {
    this.setState({
      studentSearch: e.target.value.toUpperCase()
    })
  }

  createActivity() {

    var student = []

    for (let i = 0; i < this.state.dataSource2.length; i++) {
      if (this.state.choices['choice_' + this.state.dataSource2[i].student_id] === 1) {
        student.push(this.state.dataSource2[i].student_id);
      }
    }

    var service = []

    for (let i = 0; i < this.state.services.length; i++) {
      if (this.state.serviceChoices['choice_' + this.state.services[i].service_id] === 1) {
        service.push(this.state.services[i].service_id);
      }
    }

    fetch(process.env.REACT_APP_API_URL + '/activities', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',

      body: JSON.stringify({
        date_of: this.state.date,
        description: this.state.description,
        school_id: this.state.selectedItem.school_id,
        hours: this.state.hours,
        consultant_id: this.state.consultant_id,
        co_consultant_id: this.state.co_consultant_id,
        target_id: this.state.target_id,
        number_of_parents: this.state.number_of_parents,
        student_id: student
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.createActivityService(responseJson.activityId, service);

        this.setState({
          isLoading: false
        });

        if (responseJson.error === false) {
          this.getData()
        }

      })
  }

  createActivityService(activity_id, services) {

    fetch(process.env.REACT_APP_API_URL + '/activities/service', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',

      body: JSON.stringify({
        activity_id: activity_id,
        services: services
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false
        });

        if (responseJson.error === false) {
          this.getData()
        }
      })

  }

  deleteActivity(activityId) {

    this.setState({
      isLoading: true
    })

    fetch(process.env.REACT_APP_API_URL + '/activities', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        activity_id: activityId
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.getData();

          this.setState({
            isLoading: false
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  deleteDoc = (item) => {

    this.setState({
      isLoading: true
    })

    fetch(process.env.REACT_APP_API_URL + '/activities_document', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        activity_id: item.activity_id,
        activity_document_id: item.activity_document_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.getAttachments(item);

          this.setState({
            isLoading: false,
            isDeleteDocOpen: false,

          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onNewOpen = () => {
    this.setState({
      activity_id: 0,
      work_learning_type_id: 0,
      date_of: '',
      school_id: 0,
      hours: '',
      consultant_id: 0,
      co_consultant_id: 0,
      target_id: 0,
      service_id: 0,
      number_of_parents: 0,
      description: '',
      isNewOpen: true
    })
  }

  onNewClose = () => {
    this.setState({
      isNewOpen: false
    })
  }

  onNewClick = () => {

    this.createActivity();

    this.setState({
      isStudentOpen: false,
      isNewOpen: false
    })
  }

  onArchiveOpen = (item) => {
    this.setState({
      isArchiveOpen: true,
      selectedItem: item,
    })
  }

  onArchiveClose = () => {
    this.setState({
      isArchiveOpen: false
    })
  }

  archiveActivity() {

    this.setState({
      isLoading: true
    })

    fetch(process.env.REACT_APP_API_URL + '/activities/archive', {

      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },

      body: JSON.stringify({
        is_archive: true,
        activity_id: this.state.selectedItem.activity_id
      }),

      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {

          this.getData();


          this.setState({
            isLoading: false,
            isArchiveOpen: false,
          })

        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }


  onBackClick = () => {

    this.setState({
      isStudentOpen: false
    })
  }

  onDeleteDocOpen = (item) => {
    this.setState({
      isDeleteDocOpen: true,
      selectedItem: item,
    })
  }

  onDeleteDocClose = () => {
    this.setState({
      isDeleteDocOpen: false
    })
  }

  onStudentOpen = () => {

    var isValid = this.validateForm()

    if (isValid) {

      this.setState({
        student_id: [],
        isStudentOpen: true
      })
    }
  }

  onStudentClose = () => {
    this.setState({
      isStudentOpen: false
    })
  }

  isDateValid(dateStr) {
    return !isNaN(new Date(dateStr));
  }

  validateForm() {
    var result = true;
    var consultantValid = true;
    var schoolValid = true;
    var activityDateValid = true;
    var targetValid = true;
    var hoursValid = true;
    var dateValid = true;
    var descriptionValid = true;
    var numberOfParentsValid = true;
    var serviceChoicesValid = false;

    if (this.state.consultant_id === 0) {
      consultantValid = false;
      result = false;
    }

    if (this.state.school_id === 0) {
      schoolValid = false;
      result = false;
    }

    if (this.state.target_id === 0) {
      targetValid = false;
      result = false;
    }

    if (this.state.hours === "") {
      hoursValid = false;
      result = false;
    }

    if (this.state.description === "") {
      descriptionValid = false;
      result = false;
    }

    if (this.state.date === "" || this.state.date === null || !this.isDateValid(this.state.date)) {
      dateValid = false;
      result = false;
    }

    for (let i = 0; i < this.state.services.length; i++) {
      if (this.state.serviceChoices['choice_' + this.state.services[i].service_id] === 1) {
        serviceChoicesValid = true;
      }
    }

    if (!serviceChoicesValid) {
      result = false;
    }

    this.setState({
      consultantValid: consultantValid,
      schoolValid: schoolValid,
      dateValid: dateValid,
      activityDateValid: activityDateValid,
      targetValid: targetValid,
      hoursValid: hoursValid,
      descriptionValid: descriptionValid,
      numberOfParentsValid: numberOfParentsValid,
      serviceChoicesValid: serviceChoicesValid
    })

    return result;


  }

  onAttachmentOpen = (item) => {
    this.setState({
      isAttachmentOpen: true,
      selectedItem: item,
    })
  }

  onAttachmentClose = () => {
    this.setState({
      isAttachmentOpen: false
    })
  }

  userLogout(e) {
    this.props.onLogout();
    e.preventDefault();
  }

  onNextClick(activity_id) {

    this.props.onDetailView(
      activity_id,
    )
    this.props.history.push("/profile/:profileId")
  }

  onActivityClick(activityId) {
    this.props.history.push('/activity/' + activityId)
  }

  onHourChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.hours = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onDescriptionChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.description = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onParentsChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.number_of_parents = event.target.value;

    this.setState({

      selectedItem: selectedItem
    })
  }

  onConsultantChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.consultant_id = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onCoConsultantChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.co_consultant_id = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onServiceChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.service_id = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onTargetChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.target_id = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onSchoolClick(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.school_id = event.target.value;

    this.setState({
      selectedItem: selectedItem,
      school_id: event.target.value
    })

    this.getCategories(event.target.value);
    this.getStudents(event.target.value)
  }

  onDateChange(day) {

    this.setState({
      date: day
    })
  }

  renderConsultants() {

    if (this.state.consultants !== undefined) {

      var content = this.state.consultants.map((item, key) => {
        return (
          <MenuItem key={key} value={item.consultant_id}>{item.first_name} {item.last_name}</MenuItem>
        )
      })

      return content;
    }

  }

  renderCoConsultants() {

    if (this.state.consultants !== undefined) {

      var content = this.state.consultants.map((item, key) => {
        return (
          <MenuItem key={key} value={item.co_consultant_id}>{item.co_first_name} {item.co_last_name}</MenuItem>
        )
      })

      return content;
    }

  }

  renderTargets() {

    if (this.state.targets !== undefined) {

      var content = this.state.targets.map((item, key) => {
        return (
          <MenuItem key={key} value={item.target_id}>{item.target_name}</MenuItem>
        )
      })

      return content;
    }

  }

  renderSchools() {

    if (this.state.schools !== undefined) {

      var content = this.state.schools.map((item, key) => {
        return (
          <MenuItem key={key} value={item.school_id}>{item.school_name}</MenuItem>
        )
      })

      return content;
    }

  }

  renderServices() {

    if (this.state.services !== undefined) {

      var content = this.state.services.map((item, key) => {
        return (

          <Grid key={key} item xs={6}>

            <FormControl component="fieldset" style={{ marginLeft: 30 }} error={!this.state.serviceChoicesValid}>
              <FormGroup button onClick={() => this.onServiceClick(item.service_id)}>
                <FormControlLabel
                  control={<Checkbox color='default' checked={this.state.serviceChoices['choice_' + item.service_id]} />}
                  label={item.service_name}
                />
              </FormGroup>
            </FormControl>

          </Grid>

        )
      })

      return content;
    }

  }

  renderCategories() {

    if (this.state.categories !== undefined) {

      var content = this.state.categories.map((item, key) => {
        return (
          <Grid key={key} item xs={4}>
            <FormControl component="fieldset" style={{ marginLeft: 30, marginTop: 10 }}>
              <FormGroup button onClick={() => this.onCategoryClick(item)}>
                <FormControlLabel
                  control={<Checkbox

                    value={this.state.categoryChoices['choice_' + item.category_id]} />}
                  label={<Typography style={{ fontSize: 14 }}>{item.category_name}</Typography>}
                />
              </FormGroup>
            </FormControl>
          </Grid>
        )
      })

      return content;
    }
  }

  onCategoryClick(item) {

    var categoryChoices = this.state.categoryChoices;
    var choices = this.state.choices;

    for (let i = 0; i < this.state.categories.length; i++) {
      if (this.state.categories[i].category_id === item.category_id && categoryChoices['choice_' + this.state.categories[i].category_id] === 0) {
        categoryChoices['choice_' + this.state.categories[i].category_id] = 1;

        for (let j = 0; j < item.students.length; j++) {


          for (let k = 0; k < this.state.dataSource2.length; k++) {
            if (this.state.dataSource2[k].student_id === item.students[j].student_id) {
              choices['choice_' + this.state.dataSource2[k].student_id] = 1;
            }
          }
        }
      }
      else if (this.state.categories[i].category_id === item.category_id) {
        categoryChoices['choice_' + this.state.categories[i].category_id] = 0;

        for (let j = 0; j < item.students.length; j++) {

          for (let k = 0; k < this.state.dataSource2.length; k++) {
            if (this.state.dataSource2[k].student_id === item.students[j].student_id) {
              choices['choice_' + this.state.dataSource2[k].student_id] = 0;
            }
          }
        }
      }
    }

    this.setState({
      categoryChoices: categoryChoices,
      choices: choices
    });

  }

  onFileChange = (e) => {
    let files = e.target.files;
    console.warn("data files", files)
  }

  renderStudents() {
    let filteredStudents = this.state.dataSource2.filter(
      (student) => {

        let fullName = student.first_name + " " + student.last_name

        return fullName.toUpperCase().indexOf(this.state.studentSearch) !== -1;
      }
    );

    if (filteredStudents === undefined) return null;

    var content = filteredStudents.map((item, key) => {
      return (

        <Grid key={key} item xs={4}>
          <FormControl component="fieldset" style={{ marginLeft: 30, marginTop: 10 }}>
            <FormGroup>
              <FormControlLabel
                onClick={() => this.onStudentClick(item.student_id)}
                control={<Checkbox color='default' checked={this.state.choices['choice_' + item.student_id]} />}
                label={<TableRow>
                  <TableCell style={{ width: 150 }}><Typography style={{ fontSize: 14 }}>{item.first_name + " " + item.last_name}</Typography></TableCell>
                  <TableCell><Typography style={{ fontSize: 14 }}>{item.grade_id}th Grade</Typography></TableCell>
                </TableRow>}
              />
            </FormGroup>
          </FormControl>
        </Grid>
      )
    })

    return content;

  }

  onServiceClick(serviceId) {

    this.setState({
      showValidation: false
    })

    var serviceChoices = this.state.serviceChoices;


    for (let i = 0; i < this.state.services.length; i++) {
      if (this.state.services[i].service_id === serviceId && serviceChoices['choice_' + this.state.services[i].service_id] === 0) {
        serviceChoices['choice_' + this.state.services[i].service_id] = 1;
      }
      else if (this.state.services[i].service_id === serviceId) {
        serviceChoices['choice_' + this.state.services[i].service_id] = 0;
      }
    }

    this.setState({
      serviceChoices: serviceChoices
    });
  }

  onPrintClick() {

  }

  onStudentClick(studentId) {

    this.setState({
      showValidation: false
    })

    var choices = this.state.choices;


    for (let i = 0; i < this.state.dataSource2.length; i++) {
      if (this.state.dataSource2[i].student_id === studentId && choices['choice_' + this.state.dataSource2[i].student_id] === 0) {
        choices['choice_' + this.state.dataSource2[i].student_id] = 1;
      }
      else if (this.state.dataSource2[i].student_id === studentId) {
        choices['choice_' + this.state.dataSource2[i].student_id] = 0;
      }
    }

    this.setState({
      choices: choices
    });
  }

  handleFileChange = (e) => {
    this.setState({
      file: e.target.value
    })
  }

  onCheckAll() {
    this.setState({
      showValidation: false
    })

    var choices = this.state.choices;

    for (let i = 0; i < this.state.dataSource2.length; i++) {
      if (choices['choice_' + this.state.dataSource2[i].student_id] === 0) {
        choices['choice_' + this.state.dataSource2[i].student_id] = 1;
      }
      else if (choices['choice_' + this.state.dataSource2[i].student_id] === 1) {
        choices['choice_' + this.state.dataSource2[i].student_id] = 0;
      }
    }

    this.setState({
      choices: choices
    });
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
        <h1 className="title">Activities</h1>

        <Grid container>

          <Grid item xs={1}></Grid>

          <Grid item xs={7}>

            <TextField style={{ width: '40%' }}
              value={this.state.searchText}
              onChange={(event) => this.onSearch(event.target.value)}
              type="text"
              placeholder="Search" />

          </Grid>
          <Grid item xs={3} style={{ textAlign: 'right' }}>

            <Button onClick={() => this.onNewOpen()} style={{ color: '#FFF', fontWeight: 'bold', backgroundColor: '#C9322D', marginRight: 20 }}>
              <Typography style={{ fontFamily: 'Josefin Sans', paddingRight: 10, paddingTop: 3 }}>Add Activity</Typography>
              <AddCircleIcon style={{ fontSize: 17 }}>Add</AddCircleIcon>
            </Button>

            <Button onClick={() => this.onPrintClick()}
              style={{ color: '#FFF', fontWeight: 'bold', backgroundColor: '#C9322D' }}>
              <Typography style={{ fontFamily: 'Josefin Sans', paddingRight: 10, paddingTop: 3 }}>Print</Typography>
              <PrintIcon style={{ fontSize: 17 }}>Print</PrintIcon>
            </Button>

          </Grid>

          <Grid item xs={1}></Grid>

        </Grid>

        <div className="cardContainer">

          <Grid container spacing={10}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Paper square style={{ marginTop: 10, borderRadius: 0 }}>
                <div style={{ overflowX: 'auto', maxHeight: this.state.height }}>
                  <Table style={{ height: this.state.height }}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={styles.head}>
                          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("first_name")}>
                            Consultant
                            {this.state.sortBy === "first_name" ? (
                              this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                            ) : null}
                          </div>

                        </TableCell>
                        <TableCell style={styles.head}>
                          <Grid>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("school_name")}>
                              School
                              {this.state.sortBy === "school_name" ? (
                                this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                              ) : null}
                            </div>
                          </Grid>
                        </TableCell>
                        <TableCell style={styles.head}>
                          <Grid>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("target_name")}>
                              Target
                              {this.state.sortBy === "target_name" ? (
                                this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                              ) : null}
                            </div>
                          </Grid>
                        </TableCell>
                        <TableCell style={styles.head}>
                          <Grid>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("description")}>
                              Description
                              {this.state.sortBy === "description" ? (
                                this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                              ) : null}
                            </div>
                          </Grid>
                        </TableCell>
                        <TableCell style={styles.head}>
                          <Grid>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("hours")}>
                              Hours
                              {this.state.sortBy === "hours" ? (
                                this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                              ) : null}
                            </div>
                          </Grid>
                        </TableCell>
                        <TableCell style={styles.head}>
                          <Grid>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("date_of")}>
                              Date
                              {this.state.sortBy === "date_of" ? (
                                this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                              ) : null}
                            </div>
                          </Grid>
                        </TableCell>
                        <TableCell style={styles.head}>
                          <Grid>
                            <div>
                              <div style={{ marginTop: 10, width: 30 }}></div>
                            </div>
                          </Grid>
                        </TableCell>
                        <TableCell style={styles.head}>
                          <Grid>
                            <div>
                              <div style={{ marginTop: 10, width: 50 }}></div>
                            </div>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {orderBy(this.state.filteredActivities, this.state.sortBy, this.state.sortDirection)
                        .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                        .map((row, index) => {
                          return (
                            <TableRow key={index} hover style={{ cursor: 'pointer' }}>
                              <TableCell style={{ width: 150 }} onClick={() => this.onActivityClick(row.activity_id)}>{row.first_name + " " + row.last_name}</TableCell>
                              <TableCell style={{ width: 150 }} onClick={() => this.onActivityClick(row.activity_id)}>{row.school_name}</TableCell>
                              <TableCell style={{ width: 150 }} onClick={() => this.onActivityClick(row.activity_id)}>{row.target_name}</TableCell>
                              <TableCell style={{ width: 200 }} onClick={() => this.onActivityClick(row.activity_id)}>{row.description}</TableCell>
                              <TableCell style={{ width: 30 }} onClick={() => this.onActivityClick(row.activity_id)}>{row.hours}</TableCell>
                              <TableCell style={{ width: 50 }} onClick={() => this.onActivityClick(row.activity_id)}>{moment.utc(row.date_of).format('MM/DD/YYYY')}</TableCell>

                              <TableCell style={{ width: 25 }} >
                                <IconButton onClick={() => this.getAttachments(row)}>
                                  <AttachFileIcon style={{ fontSize: 24, color: '#C9322D' }}></AttachFileIcon>
                                </IconButton>
                              </TableCell>

                              <TableCell style={{ width: 25 }} >
                                <IconButton onClick={() => this.onArchiveOpen(row)}>
                                  <ArchiveIcon style={{ fontSize: 24, color: '#C9322D' }}></ArchiveIcon>
                                </IconButton>
                              </TableCell>
                            </TableRow>

                          )
                        }
                        )}

                      <TableRow>
                        <TableCell colSpan={6} />
                      </TableRow>

                    </TableBody>
                  </Table>

                </div>
                <TablePagination
                  rowsPerPageOptions={[25]}
                  component="div"
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.currentPage}

                  count={this.state.filteredActivities.length}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onPageChange={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Paper>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </div>

        {/* Add activity modal/dialog below */}

        <Dialog open={this.state.isNewOpen}
          maxWidth='md'
          fullWidth='true'
          onClose={this.onNewClose}
          aria-labelledby="form-dialog-title">

          <DialogTitle onClose={this.onNewClose}>
            New Activity
          </DialogTitle>

          <DialogContent>

            <Grid container spacing={2} style={{ marginTop: 10 }}>

              <Grid item xs={6}>
                <FormControl variant="standard" style={{ width: '100%' }} error={!this.state.consultantValid}>
                  <InputLabel htmlFor="consultant">Consultant</InputLabel>
                  <Select
                    value={this.state.consultant_id}
                    onChange={(event) => this.setState({ consultant_id: event.target.value })}>

                    <MenuItem disabled value={0}>Choose Consultant</MenuItem>

                    {this.renderConsultants()}
                  </Select>
                  {!this.state.consultantValid && <FormHelperText>This is required!</FormHelperText>}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <InputLabel htmlFor="coConsultant">Co-Consultant</InputLabel>
                  <Select
                    value={this.state.co_consultant_id}
                    onChange={(event) => this.setState({ co_consultant_id: event.target.value })}>

                    <MenuItem value={0}>Choose Consultant</MenuItem>

                    {this.renderConsultants()}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>

                <FormControl variant="standard" style={{ width: '100%' }} error={!this.state.schoolValid}>
                  <InputLabel htmlFor="goal-type">School</InputLabel>
                  <Select
                    value={this.state.school_id}
                    onChange={(event) => this.onSchoolClick(event)}>
                    {this.renderSchools()}
                  </Select>
                  {!this.state.schoolValid && <FormHelperText>This is required!</FormHelperText>}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl variant="standard" style={{ width: '100%' }} error={!this.state.targetValid}>
                  <InputLabel htmlFor="goal-type">Target Audience</InputLabel>
                  <Select
                    value={this.state.target_id}
                    onChange={(event) => this.setState({ target_id: event.target.value })}>
                    {this.renderTargets()}
                  </Select>
                  {!this.state.targetValid && <FormHelperText>This is required!</FormHelperText>}
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl style={{ width: '100%', marginTop: 15 }} error={!this.state.dateValid}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      componentsProps={{
                        actionBar: {
                          actions: ['clear'],
                        },
                      }}
                      minDate={moment().subtract(7, 'year')}
                      maxDate={moment()}
                      value={this.state.date}
                      onChange={(theDate) => this.onDateChange(theDate)}
                      renderInput={(params) => <TextField variant="standard" {...params} />}
                    />
                  </LocalizationProvider>
                  {!this.state.dateValid && <FormHelperText>This must be a valid date!</FormHelperText>}
                </FormControl>

              </Grid>

              <Grid item xs={4}>

                <FormControl style={{ width: '100%' }} error={!this.state.hoursValid}>
                  <InputLabel htmlFor="hours">Hours</InputLabel>
                  <Input variant="filled" error={!this.state.hoursValid}
                    value={this.state.hours}
                    onChange={(event) => this.setState({ hours: event.target.value })}
                    id="hours" aria-describedby="my-helper-text" />
                  {!this.state.hoursValid && <FormHelperText>This is required!</FormHelperText>}
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }} error={!this.state.numberOfParentsValid}>
                  <InputLabel htmlFor="desc">Number of Parents</InputLabel>
                  <Input variant="filled" error={!this.state.numberOfParentsValid}
                    value={this.state.number_of_parents}
                    onChange={(event) => this.setState({ number_of_parents: event.target.value })}
                    id="desc" aria-describedby="my-helper-text" />
                  {!this.state.numberOfParentsValid && <FormHelperText>This is required!</FormHelperText>}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl style={{ width: '100%' }} error={!this.state.descriptionValid}>
                  <InputLabel htmlFor="desc">Description</InputLabel>
                  <Input variant="filled" error={!this.state.descriptionValid}
                    multiline={true}
                    rows="4"
                    value={this.state.description}
                    onChange={(event) => this.setState({ description: event.target.value })}
                    id="desc" aria-describedby="my-helper-text" />
                  {!this.state.descriptionValid && <FormHelperText>This is required!</FormHelperText>}
                </FormControl>
              </Grid>


              <Grid item xs={12}>
                <FormControl style={{ marginTop: 10, }} error={!this.state.serviceChoicesValid}>
                  <MenuItem disabled value={0}>Select Service</MenuItem>
                  {!this.state.serviceChoicesValid && <FormHelperText>This is required!</FormHelperText>}
                  <Grid container>
                    {this.renderServices()}
                  </Grid>


                </FormControl>

              </Grid>

            </Grid>


          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.onStudentOpen()}>
              Next
            </Button>
          </DialogActions>
        </Dialog>

        {/* Add part 2 activity modal/dialog below */}
        <Dialog open={this.state.isStudentOpen}
          onClose={this.onStudentClose}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          style={{ padding: 20 }}>
          <DialogTitle id="customized-dialog-title"
            onClose={this.onStudentClose}>
          </DialogTitle>

          <DialogContent>
            {this.state.categories === 0 ?
              <Grid>
                <div style={{ display: 'flex', justifyContent: 'row', position: 'sticky' }}>

                  <Typography style={{ fontSize: 18, marginBottom: 10, paddingTop: 10 }}>Select Students</Typography>

                  <input
                    value={this.state.studentSearch}
                    onChange={this.updateStudentSearch.bind(this)}
                    style={{ backgroundColor: '#f7f7f7', border: 'none', borderRadius: 5, alignContent: 'center', paddingRight: 55, paddingLeft: 20, paddingTop: 2, paddingBottom: 2, marginRight: 20, marginLeft: 20, fontSize: 14, letterSpacing: 2 }}
                    type="text"
                    placeholder="SEARCH" />

                  <Button onClick={() => this.onCheckAll()} style={{ color: '#FFF', fontWeight: 'bold', backgroundColor: '#C9322D', marginRight: 20 }}>
                    Check all
                  </Button>
                </div>
                <hr />

                <div style={{ height: 550, overflowX: 'auto' }}>
                  <Grid container>
                    {this.renderStudents()}
                  </Grid>
                </div>

              </Grid>
              : this.state.categories !== 0 ?
                <Grid>
                  <Typography style={{ fontSize: 18, marginBottom: 10 }}>Select Category</Typography>
                  <hr></hr>
                  <div style={{ height: 100, overflowX: 'auto' }}>
                    <Grid container>
                      {this.renderCategories()}
                    </Grid>
                  </div>
                  <div style={{ marginTop: 20, display: 'flex', justifyContent: 'row', position: 'sticky' }}>

                    <Typography style={{ fontSize: 18, marginBottom: 10, paddingTop: 10 }}>Select Students</Typography>

                    <input
                      value={this.state.studentSearch}
                      onChange={this.updateStudentSearch.bind(this)}
                      style={{ backgroundColor: '#f7f7f7', border: 'none', borderRadius: 5, alignContent: 'center', paddingRight: 55, paddingLeft: 20, paddingTop: 2, paddingBottom: 2, marginRight: 20, marginLeft: 20, fontSize: 14, letterSpacing: 2 }}
                      type="text"
                      placeholder="SEARCH" />

                    <Button onClick={() => this.onCheckAll()} style={{ color: '#FFF', fontWeight: 'bold', backgroundColor: '#C9322D', marginRight: 20 }}>
                      Check all
                    </Button>
                  </div>
                  <hr />

                  <div style={{ height: 450, overflowX: 'auto' }}>
                    <Grid container>
                      {this.renderStudents()}
                    </Grid>
                  </div>
                </Grid>
                : null
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.onBackClick()}>
              Back
            </Button>
            <Button onClick={() => this.onNewClick()}>
              Create
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isArchiveOpen}
          onClose={this.onArchiveClose}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onArchiveClose}>
            Archive Activity
          </DialogTitle>
          <DialogContent>
            <Typography>Are you sure you would like to archive this activity?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.onArchiveClose()} color="secondary">
              Cancel
            </Button>
            <Button onClick={() => this.archiveActivity()} color="primary">
              Archive
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete activity DOCUMENT modal/dialog below */}
        <Dialog open={this.state.isDeleteDocOpen}
          onClose={this.onDeleteDocClose}
          maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onAttachmentClose}>
            Delete Attachment
          </DialogTitle>
          <DialogContent>
            Are you sure you would like to delete this attachment?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.onDeleteDocClose()}>
              Cancel
            </Button>
            <Button onClick={() => this.deleteDoc(this.state.selectedItem)}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* Add attachment to activity modal/dialog below */}
        <div>
          <Dialog open={this.state.isAttachmentOpen}
            onClose={this.onAttachmentClose}
            maxWidth="xl"
            fullWidth={true}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title"
              onClose={this.onAttachmentClose}>
              Attachments
            </DialogTitle>
            <DialogActions >
              <Button onClick={() => console.log("upload function here")} >
                To upload more attachments, simply drag them onto the area below
                <ArrowDownwardIcon style={{ paddingLeft: 7, fontSize: 20 }}>Upload</ArrowDownwardIcon>
              </Button>
            </DialogActions>
            <Dropzone
              noClick
              noKeyboard

              ref={this.dropzoneRef}
              onDrop={this.onDrop}>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />

                  <Grid container>
                    {this.renderDocuments()}
                  </Grid>

                </div>
              )}
            </Dropzone>

          </Dialog>
        </div>

      </div>
    );

  }
}

Activities.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    userId: state.auth.userId,
    userName: state.auth.userName,
    token: state.auth.token,
    activity_id: state.activity.activity_id,
    isAdmin: state.auth.isAdmin,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Activities);