import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import TrashIcon from '@mui/icons-material/Delete';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, ListItem, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

const invertSort = {
    asc: "desc",
    desc: "asc"
}

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" onClick={onClose}
                    sx={{ position: 'absolute', right: 10, top: 20 }} >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class CategoryScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            refreshing: false,
            isLoading: true,
            categories: [],
            isAddOpen: false,
            category_id: 0,
            category_name: "",
            school_id: 0,
            isAddStudentOpen: false,
            isEditOpen: false,
            dataSource: [],
            filteredDataSource: [],
            availableStudents: [],
            filteredAvailableStudents: [],
            choices: [],
            search: '',
            checkAll: false,
            studentCategory: [],
            isDeleteOpen: false,
            selectedItem: {},
            height: 700,
            selected: false,
            rowsPerPage: 25,
            currentPage: 0
        };

        this.onSearch = this.onSearch.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.onEditClose = this.onEditClose.bind(this);
        this.onDeleteClose = this.onDeleteClose.bind(this);
    }


    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));

        this.getData();

        this.updateDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let update_height = window.innerHeight - 350;
        this.setState({ height: update_height });
    }

    getData() {
        fetch(process.env.REACT_APP_API_URL + '/categories/' + this.props.match.params.categoryNumber, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error == false) {
                    if (responseJson.results.length > 0) {
                        this.setState({
                            isLoading: false,
                            category_id: responseJson.results[0].category_id,
                            category_name: responseJson.results[0].category_name,
                            school_id: responseJson.results[0].school_id
                        });
                    }
                }
            })

        fetch(process.env.REACT_APP_API_URL + '/students/category/' + this.props.match.params.categoryNumber, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    isLoading: false,
                    dataSource: responseJson.results,
                    filteredDataSource: responseJson.results
                });
            })
            .catch((error) => {

            });
    }

    addStudent() {

        var student = [];


        for (let i = 0; i < this.state.availableStudents.length; i++) {
            if (this.state.choices['choice_' + this.state.availableStudents[i].student_id] === true) {
                student.push(this.state.availableStudents[i].student_id);
            }
        }

        fetch(process.env.REACT_APP_API_URL + '/student_categories', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit',

            body: JSON.stringify({
                category_id: this.state.category_id,
                student_id: student,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    isLoading: false
                });
                if (responseJson.error === false) {
                    this.getData();
                } else {
                    // this.props.onLogout();
                }
            })
    }

    deleteStudent() {

        this.setState({
            isLoading: true
        })

        fetch(process.env.REACT_APP_API_URL + '/student/category', {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                category_id: this.state.category_id,
                student_id: this.state.selectedItem.student_id
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false
                    })
                }

                this.getData();
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    onAddOpen = () => {
        this.setState({
            isAddOpen: true
        })
    }

    onDeleteClick() {

        this.deleteStudent();

        this.setState({
            isDeleteOpen: false,
        })
    }

    onDeleteClose() {

        this.setState({
            isDeleteOpen: false,
        })
    }

    onDeleteOpen(item) {
        this.setState({
            isDeleteOpen: true,
            selectedItem: item
        })
    }

    onAddStudent() {

        fetch(process.env.REACT_APP_API_URL + '/students/category/available/' + this.state.category_id, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.error === false) {
                    this.setState({
                        availableStudents: responseJson.results,
                        filteredAvailableStudents: responseJson.results
                    });
                } else {
                    //this.props.onLogout();
                }
            })

        this.setState({
            choices: [],
            searchText: '',
            isAddStudentOpen: true
        })
    }

    onNewStudentClose = () => {
        this.setState({
            isAddStudentOpen: false
        })
    }

    onNewStudentClick() {

        this.addStudent();

        this.setState({
            isAddStudentOpen: false
        })
    }

    saveCategory() {

        fetch(process.env.REACT_APP_API_URL + '/category', {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                category_id: this.state.category_id,
                category_name: this.state.category_name
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        isEditOpen: false
                    })
                }

                this.getData();
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    handleChangePage(event, newPage) {
        this.setState({
            currentPage: newPage
        })
    }

    handleChangeRowsPerPage(event) {
        this.setState({
            rowsPerPage: event.target.value
        })
    }

    onSearch(event) {

        const newData = this.state.availableStudents.filter(function (item) {
            const itemData = item.first_name.toUpperCase() + " " + item.last_name.toUpperCase()
            const gradeData = item.grade_name.toUpperCase();
            const textData = event.target.value.toUpperCase()

            return itemData.indexOf(textData) > -1 || gradeData.indexOf(textData) > -1;
        })

        this.setState({
            filteredAvailableStudents: newData,
            search: event.target.value
        })
    }

    renderAvailableStudents() {

        if (this.state.filteredAvailableStudents === undefined) return null;

        if (this.state.filteredAvailableStudents.length === 0) {
            return (
                <FormControl component="fieldset" style={{ marginLeft: 30 }}>
                    <FormGroup>
                        <FormLabel>
                            No Students
                        </FormLabel>
                    </FormGroup>
                </FormControl>
            )
        } else {

            if (this.state.filteredAvailableStudents === undefined) return null;

            var content = this.state.filteredAvailableStudents.map((item, key) => {

                return (
                    <ListItem key={key}>
                        <FormControl component="fieldset" style={{ marginLeft: 30 }}>
                            <FormGroup>
                                <FormControlLabel
                                    onClick={() => this.onStudentClick(item.student_id)}
                                    control={<Checkbox
                                        checked={this.state.choices['choice_' + item.student_id] ? true : false} />}
                                    label={<TableRow>
                                        <TableCell style={{ width: 150 }}>{item.first_name + " " + item.last_name}</TableCell>
                                        <TableCell>{item.grade_name}</TableCell>
                                    </TableRow>}
                                />
                            </FormGroup>
                        </FormControl>
                    </ListItem>
                )
            })

            return content;

        }

    }

    onStudentClick(studentId) {

        this.setState({
            showValidation: false
        })

        var choices = this.state.choices;

        for (let i = 0; i < this.state.availableStudents.length; i++) {
            if (this.state.availableStudents[i].student_id === studentId) {
                if (choices['choice_' + this.state.availableStudents[i].student_id] === false
                    || choices['choice_' + this.state.availableStudents[i].student_id] === undefined) {
                    choices['choice_' + this.state.availableStudents[i].student_id] = true;
                } else {
                    choices['choice_' + this.state.availableStudents[i].student_id] = false;
                }
            }
        }

        this.setState({
            choices: choices
        });
    }

    onCheckAll() {

        var checkAll = !this.state.checkAll;

        var choices = this.state.choices;

        for (let i = 0; i < this.state.availableStudents.length; i++) {
            choices['choice_' + this.state.availableStudents[i].student_id] = checkAll == true ? true : false;
        }

        this.setState({
            checkAll: checkAll,
            choices: choices
        });
    }

    onEditClick() {
        this.setState({
            isEditOpen: true
        })
    }

    onEditClose() {
        this.setState({
            isEditOpen: false
        })
    }


    render() {

        return (
            <div style={{ marginTop: 130 }}>

                <Dialog open={this.state.isEditOpen}
                    onClose={this.onEditClose} fullWidth
                    aria-labelledby="form-dialog-title">

                    <DialogTitle id="customized-dialog-title"
                        onClose={this.onEditClose}>
                        Edit Category Name
                    </DialogTitle>

                    <DialogContent>

                        <Grid container>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    value={this.state.category_name}
                                    onChange={(evt) => this.setState({ category_name: evt.target.value })}
                                    type="text" variant='standard'
                                    placeholder="Category Name" />
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.onEditClose()} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => this.saveCategory()} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                <h1 className="title">Category : {this.state.category_name} <IconButton onClick={() => this.onEditClick()}><CreateIcon style={{ color: '#C9322D', fontSize: 28 }}></CreateIcon></IconButton></h1>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap', }}>
                    <Button onClick={() => this.onAddStudent()} style={{ color: '#FFF', fontWeight: 'bold', backgroundColor: '#C9322D', marginRight: 20 }}>
                        <Typography style={{ fontFamily: 'Josefin Sans', paddingRight: 10, paddingTop: 3 }}>Add Students</Typography>
                        <AddCircleIcon style={{ fontSize: 17 }}>Add</AddCircleIcon>
                    </Button>
                </div>

                <div className="cardContainer">

                    <Grid container spacing={10}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={8}>
                            <Paper square style={{ marginTop: 10, borderRadius: 0 }}>
                                <div style={{ overflowX: 'auto', maxHeight: this.state.height }}>
                                    <Table style={{ height: this.state.height }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={styles.head}>
                                                    <div>
                                                        <div style={{ marginTop: 10, width: 250 }}>First Name</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell style={styles.head}>
                                                    <div>
                                                        <div style={{ marginTop: 10, width: 250 }}>Last Name</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell style={styles.head}>
                                                    <div>
                                                        <div style={{ marginTop: 10, width: 250 }}>Actions</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {orderBy(this.state.filteredDataSource, this.state.sortBy, this.state.sortDirection)
                                                .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                                                .map((row, index) => {
                                                    return (
                                                        <TableRow key={index} hover style={{ cursor: 'pointer' }}>
                                                            <TableCell>{row.first_name}</TableCell>
                                                            <TableCell>{row.last_name}</TableCell>
                                                            <TableCell>
                                                                <IconButton>
                                                                    <TrashIcon style={{ fontSize: 24, color: '#C9322D' }} onClick={() => this.onDeleteOpen(row)}></TrashIcon>
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>

                                                    )
                                                }
                                                )}

                                            <TableRow>
                                                <TableCell colSpan={6} />
                                            </TableRow>

                                        </TableBody>
                                    </Table>

                                </div>
                                <TablePagination
                                    rowsPerPageOptions={[25]}
                                    component="div"
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.currentPage}
                                    count={this.state.filteredDataSource.length}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onPageChange={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>

                </div>

                {/* Add students modal/dialog below */}
                <Dialog open={this.state.isAddStudentOpen}
                    onClose={this.onNewStudentClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="xl"
                    fullScreen='true'
                    style={{ padding: 150, height: 530 }}>
                    <DialogTitle id="customized-dialog-title"
                        onClose={this.onNewStudentClose}>
                        Select Students
                    </DialogTitle>

                    <DialogContent>

                        <Grid>
                            <div style={{ display: 'flex', justifyContent: 'row', position: 'sticky' }}>

                                <input
                                    value={this.state.search}
                                    onChange={(event) => this.onSearch(event)}
                                    style={{ backgroundColor: '#f7f7f7', border: 'none', borderRadius: 5, alignContent: 'center', paddingRight: 55, paddingLeft: 20, paddingTop: 2, paddingBottom: 2, marginRight: 20, marginLeft: 20, fontSize: 14, letterSpacing: 2 }}
                                    type="text"
                                    placeholder="SEARCH" />

                                <Button variant="contained"
                                    style={{ color: '#FFF', fontWeight: 'bold', backgroundColor: '#C9322D', marginRight: 20 }}
                                    onClick={() => this.onCheckAll()}>
                                    {this.state.checkAll ? 'UnCheck All' : 'Check All'}
                                </Button>
                            </div>
                            <hr />

                            <Grid cols={3} cellHeight={50} style={{ overflow: 'auto', height: 300 }}>
                                {this.renderAvailableStudents()}
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained"
                            style={{ marginTop: 20, color: '#FFF', fontWeight: 'bold', backgroundColor: '#C9322D', marginRight: 20 }}
                            onClick={() => this.onNewStudentClick()} >
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Delete activity modal/dialog below */}
                <Dialog open={this.state.isDeleteOpen}
                    onClose={this.onDeleteClose}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title"
                        onClose={this.onDeleteClose}>
                        Remove Student
                    </DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you would like to remove this student?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.onDeleteClose()}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.onDeleteClick()} >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userId: state.auth.userId,
        userName: state.auth.userName,
        token: state.auth.token,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => { dispatch(logout()); },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryScreen);