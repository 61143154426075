import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import CategoryScreen from '../src/screens/categories/category/index';
import CategoriesScreen from '../src/screens/categories/index';
import ProfileScreen from '../src/screens/profile/index';
import ReportScreen from '../src/screens/reports/index';
import LoggedOutScreen from '../src/screens/shared/loggedOut/index';
import TeacherHeaderBar from '../src/screens/shared/sidebar/teacherHeader';
import ActivityScreen from './screens/activities/activity/index';
import ActivitiesScreen from './screens/activities/index';
import PreviewScreen from './screens/activities/preview/index';
import LoginScreen from './screens/login/index';
import HeaderBar from './screens/shared/sidebar/header';
import StudentScreen from './screens/students/index';
import UserScreen from './screens/users/index';

let theme = createTheme({
  typography: {
    useNextVariants: true,
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  palette: {
    primary: {
      light: '#78E5C9',
      main: '#C9312C',
      dark: '#4D4D50',
    },
    secondary: {
      main: '#2A2042',
      dark: '#4D4D50'
    },
  },
  shape: {
    borderRadius: 8,
  }
});

theme = createTheme(theme, {
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#1F3545',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
        label: {
          textTransform: 'initial',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTextField: {
      root: {
        borderRadius: 0,
      },
      label: {
        textTransform: 'initial',
      },
      [`& fieldset`]: {
        borderRadius: 0,
      },
      contained: {
        borderRadius: 0,
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiCard: {
      root: {
        borderRadius: 0
      }
    },
    MuiExpansionPanel: {
      root: {
        square: true
      },
      rounded: {
        borderRadius: 0
      }
    },
    MuiPaper: {
      root: {
        square: true,
        borderRadius: 0
      },
      rounded: {
        borderRadius: 0
      }
    },
    MuiMenuItem: {
      root: {
        background: 'transparent',
        '&$selected': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing.unit,
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: '#2A2042',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        margin: '0 16px',
        minWidth: 0,
        [theme.breakpoints.up('md')]: {
          minWidth: 0,
        },
      },
      labelContainer: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
        },
      }
    },
    MuiLinearProgress: {

      determinate: {
        light: '#52B78B',
        main: '#52B78B',
        dark: '#51B936',
      }
    },
    MuiInput: {
      root: {

        '&$disabled': {
          color: '#000'
        }
      }
    },
    MuiRadio: {
      root: {
        '&$disabled': {
          color: '#000'
        }
      }
    },
    MuiFormControlLabel: {
      label: {
        '&$disabled': {
          color: '#000'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing.unit,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
});

const drawerWidth = 200;

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  }
}

class Application extends React.Component {
  state = {
    mobileOpen: false,
  };

  render() {

    const { classes } = this.props;

    if (this.props.isLoggedIn && this.props.isAdmin === "Yes") {
      return (
        <div>
          <BrowserRouter>
            <ThemeProvider theme={theme}>

              <LoggedOutScreen />
              <HeaderBar />
              <Switch>
                <Redirect exact from="/" to="activities" />
                <Route exact path="/activities" component={ActivitiesScreen} />
                <Route path="/profile" component={ProfileScreen} />
                <Route path="/activity/:activityId" component={ActivityScreen} />
                <Route path="/students" component={StudentScreen} />
                <Route path="/users" component={UserScreen} />
                <Route path="/reports" component={ReportScreen} />
                <Route path="/preview" component={PreviewScreen} />
                <Route path="/categories" component={CategoriesScreen} />
                <Route path="/category/:categoryNumber" component={CategoryScreen} />
              </Switch>

            </ThemeProvider>
          </BrowserRouter>
        </div>
      );
    }
    else if (this.props.isLoggedIn && this.props.isAdmin !== "Yes") {
      return (
        <BrowserRouter>
          <ThemeProvider theme={theme}>

            <LoggedOutScreen />
            <TeacherHeaderBar />
            <Switch>
              <Redirect exact from="/" to="activities" />
              <Route exact path="/activities" component={ActivitiesScreen} />
              <Route path="/activity/:activityId" component={ActivityScreen} />
              <Route path="/preview" component={PreviewScreen} />
              <Route path="/students" component={StudentScreen} />
              <Route path="/categories" component={CategoriesScreen} />
              <Route path="/category/:categoryNumber" component={CategoryScreen} />
            </Switch>

          </ThemeProvider>
        </BrowserRouter>
      );
    }
    else {
      return (
        <BrowserRouter>
          <ThemeProvider theme={theme}>

            <Switch>
              <Route path="/" component={LoginScreen} />
              <Route component={LoginScreen} />
            </Switch>

          </ThemeProvider>
        </BrowserRouter>
      )
    }
  }
}

Application.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    isAdmin: state.auth.isAdmin,
    username: state.auth.username
  };
}

export default connect(mapStateToProps)(withStyles(styles)(Application));