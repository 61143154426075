import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArchiveIcon from '@mui/icons-material/Archive';
import CloseIcon from '@mui/icons-material/Close';
import ToggleButton from '@mui/lab/ToggleButton';
import {
    Button, Card, CardContent, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup, Grid, IconButton, Input, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import styles from './style';
import './style.css';


const invertSort = {
    asc: "desc",
    desc: "asc"
}

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" onClick={onClose}
                    sx={{ position: 'absolute', right: 10, top: 20 }} >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class CategoriesScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            route: 'UserScreen',
            refreshing: false,
            isLoading: true,
            height: 100,
            rowsPerPage: 25,
            currentPage: 0,
            categories: [],
            showArchive: false,
            isAddOpen: false,
            schools: [],
            filteredCategories: [],
            category_name: "",
            schoolId: 0,
            isAddStudentOpen: false,
            category_id: 0,
            dataSource2: [],
            choices: [],
            searchText: '',
            studentCategory: [],
            isDeleteOpen: false,
            isArchiveOpen: false,
            categoryId: 0,
            sortBy: '',
            sortDirection: 'desc'
        };

        this.onSearch = this.onSearch.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.onArchiveClose = this.onArchiveClose.bind(this);
        this.onDeleteClose = this.onDeleteClose.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));

        this.getData(this.state.showArchive);

        this.updateDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }


    updateDimensions() {
        let update_height = window.innerHeight - 375;
        this.setState({ height: update_height });
    }

    getData(showArchive) {

        var url = "";

        if (showArchive) {
            url = process.env.REACT_APP_API_URL + '/categories/archive/1';
        } else {
            url = process.env.REACT_APP_API_URL + '/categories/archive/0';
        }

        fetch(url, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.authenticated === false) {
                    this.props.onLogout();
                } else {

                    this.setState({
                        isLoading: false,
                        categories: responseJson.results,
                        filteredCategories: responseJson.results
                    });

                    this.onSearch(this.state.searchText, this.state.schoolId)
                }
            })

        fetch(process.env.REACT_APP_API_URL + '/schools', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                this.setState({
                    isLoading: false,
                    schools: responseJson.schools
                });
            })
            .catch((error) => {

            });

        fetch(process.env.REACT_APP_API_URL + '/student_categories', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    isLoading: false,
                    studentCategory: responseJson.results
                })
            })
    }

    addCategory() {

        fetch(process.env.REACT_APP_API_URL + '/categories', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit',

            body: JSON.stringify({
                category_name: this.state.category_name,
                school_id: this.state.schoolId,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    isLoading: false
                });
                if (responseJson.error === false) {
                    this.getData()
                } else {
                    // this.props.onLogout();
                }
            })
    }

    deleteCategory() {

        this.setState({
            isLoading: true
        })

        fetch(process.env.REACT_APP_API_URL + '/category', {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                category_id: this.state.categoryId
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.getData(this.state.showArchive);
                    this.setState({
                        isLoading: false,
                        isDeleteOpen: false
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    archiveCategory() {

        this.setState({
            isLoading: true
        })

        console.log('here');

        fetch(process.env.REACT_APP_API_URL + '/category/archive', {
            method: "PATCH",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                category_id: this.state.categoryId,
                is_archive: true
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {

                    this.getData(this.state.showArchive);

                    this.setState({
                        isLoading: false,
                        isArchiveOpen: false
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    addStudent() {

        var student = []

        for (let i = 0; i < this.state.dataSource2.length; i++) {
            if (this.state.choices['choice_' + this.state.dataSource2[i].student_id] === 1) {
                student.push(this.state.dataSource2[i].student_id);
            }
        }

        fetch(process.env.REACT_APP_API_URL + '/student_categories', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit',

            body: JSON.stringify({
                category_id: this.state.category_id,
                student_id: student,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    isLoading: false
                });
                if (responseJson.error === false) {
                    this.getData(this.state.showArchive)
                } else {
                    // this.props.onLogout();
                }
            })
    }

    getStudents(schoolId, category_id) {

        fetch(process.env.REACT_APP_API_URL + '/students/school/' + schoolId + '/' + schoolId + '/' + category_id, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    isLoading: false,
                    dataSource2: responseJson.students
                });

                var choices = [];

                if (responseJson.students !== undefined) {
                    for (let i = 0; i < responseJson.students.length; i++) {
                        choices['choice_' + responseJson.students[i].student_id] = 0;
                    }
                }

                this.setState({
                    choices: choices
                })

            })
            .catch((error) => {

            });
    }

    onAddOpen = () => {
        this.setState({
            isAddOpen: true
        })
    }


    onDeleteClose() {

        this.setState({
            isDeleteOpen: false,
        })
    }

    onDeleteOpen(i) {

        this.setState({
            isDeleteOpen: true,
            categoryId: i
        })
    }

    onArchiveClose() {

        this.setState({
            isArchiveOpen: false,
        })
    }

    onArchiveOpen(item) {
        this.setState({
            isArchiveOpen: true,
            categoryId: item
        })
    }


    onAddStudent = (category_id, schoolId) => {

        this.getStudents(schoolId)

        this.onGetStudents(category_id)
    }

    onGetStudents = (category_id) => {
        this.setState({
            isAddStudentOpen: true,
            category_id: category_id
        })
    }
    onNewStudentClose = () => {
        this.setState({
            isAddStudentOpen: false
        })
    }

    onNewClose = () => {
        this.setState({
            isAddOpen: false
        })
    }

    onNewClick = () => {

        this.addCategory();

        this.setState({
            isAddOpen: false
        })
    }

    onNewStudentClick = () => {

        this.addStudent();

        this.setState({
            isAddStudentOpen: false
        })
    }

    updateSearch = (e) => {
        this.setState({
            search: e.target.value.toUpperCase()
        })
    }

    renderSchools() {

        var content = this.state.schools.map((item, key) => {
            return (
                <MenuItem key={key} value={item.school_id}>{item.school_name}</MenuItem>
            )
        })
        return content;
    }

    renderStudents() {

        var content = this.state.dataSource2.map((item, key) => {

            let fullName = item.first_name + " " + item.last_name


            if (this.state.dataSource2 === []) {
                return (
                    <Card >
                        <CardContent>
                            <div>No students to add</div>
                        </CardContent>
                    </Card>
                )
            }

            else {
                return (
                    <Card >
                        <CardContent>
                            <FormControl component="fieldset" style={{ marginLeft: 30 }}>
                                <FormGroup button onClick={() => this.onStudentClick(item.student_id)}>
                                    <FormControlLabel
                                        control={<Checkbox color='default' checked={this.state.choices['choice_' + item.student_id]} />}
                                        label={fullName}
                                    />
                                </FormGroup>
                            </FormControl>
                        </CardContent>
                    </Card>
                )
            }
        })

        return content;

    }

    onSearch = (searchText, schoolId) => {

        const newData = this.state.categories.filter(function (item) {
            const nameData = item.category_name.toUpperCase()
            const schoolData = item.school_id;
            const searchData = searchText.toUpperCase()

            if (searchData === "" && schoolId !== 0) {
                return schoolData === schoolId ? true : false
            } else if (searchData !== "" && schoolId === 0) {
                return nameData.indexOf(searchData) > -1
            } else if (searchData !== "" && schoolId !== 0) {
                return nameData.indexOf(searchData) > -1 && schoolData === schoolId ? true : false
            } else {
                return true;
            }
        })
        this.setState({
            filteredCategories: newData,
            searchText: searchText,
            schoolId: schoolId
        })
    }

    handleChangePage(event, newPage) {
        this.setState({
            currentPage: newPage
        })
    }

    handleChangeRowsPerPage(event) {
        this.setState({
            rowsPerPage: event.target.value
        })
    }

    handleSort = (col) => {
        this.setState(state => ({
            sortBy: col,
            sortDirection: state.sortBy === col ? invertSort[state.sortDirection] : 'asc'
        }));
    }

    onCategoryClick(categoryNumber) {
        this.props.history.push('/category/' + categoryNumber)
    }

    showArchiveChange() {

        var showArchive = !this.state.showArchive;

        this.setState({
            showArchive: showArchive
        })

        this.getData(showArchive);
    }

    onStudentClick(studentId) {

        this.setState({
            showValidation: false
        })

        var choices = this.state.choices;


        for (let i = 0; i < this.state.dataSource2.length; i++) {
            if (this.state.dataSource2[i].student_id === studentId && choices['choice_' + this.state.dataSource2[i].student_id] === 0) {
                choices['choice_' + this.state.dataSource2[i].student_id] = 1;
            }
            else if (this.state.dataSource2[i].student_id === studentId) {
                choices['choice_' + this.state.dataSource2[i].student_id] = 0;
            }
        }

        this.setState({
            choices: choices
        });
    }


    onCheckAll() {
        this.setState({
            showValidation: false
        })

        var choices = this.state.choices;

        for (let i = 0; i < this.state.dataSource2.length; i++) {
            if (choices['choice_' + this.state.dataSource2[i].student_id] === 0) {
                choices['choice_' + this.state.dataSource2[i].student_id] = 1;
            }
            else if (choices['choice_' + this.state.dataSource2[i].student_id] === 1) {
                choices['choice_' + this.state.dataSource2[i].student_id] = 0;
            }
        }

        this.setState({
            choices: choices
        });
    }

    render() {

        return (
            <div style={{ marginTop: 130 }}>

                <h1 className="title">Categories</h1>

                <Grid container>

                    <Grid item xs={1}></Grid>

                    <Grid item xs={3}>
                        <TextField style={{ width: '75%' }}
                            value={this.state.searchText}
                            onChange={(event) => this.onSearch(event.target.value, this.state.schoolId)}
                            type="text"
                            placeholder="Search" />
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl style={{ margin: 0, padding: 0 }}>
                            <InputLabel id="filter_school">Schools</InputLabel>
                            <Select style={{ width: 300 }}
                                labelId="filter_school"
                                id="filter_school_list"
                                value={this.state.schoolId}
                                onChange={(event) => this.onSearch(this.state.searchText, event.target.value)}>
                                <MenuItem value={0}>All Schools</MenuItem>
                                {this.renderSchools()}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={2}>

                        <ToggleButton
                            value="showArchive"
                            style={this.state.showArchive ? { backgroundColor: '#C9322D', color: '#FFF' } : { backgroundColor: '#FFF' }}
                            selected={this.state.showArchive}
                            onChange={() => { this.showArchiveChange() }}>
                            <Typography style={{ fontFamily: 'Josefin Sans', paddingRight: 10, paddingTop: 3 }}>Show Archive</Typography>
                            <ArchiveIcon style={{ fontSize: 17 }} />
                        </ToggleButton>

                    </Grid>
                    <Grid item xs={2}>

                        <Button onClick={(item) => this.onAddOpen(item)} style={{
                            color: '#FFF',
                            fontWeight: 'bold', backgroundColor: '#C9322D'
                        }}>
                            <Typography style={{ fontFamily: 'Josefin Sans', paddingRight: 10, paddingTop: 3 }}>Add Category</Typography>
                            <AddCircleIcon style={{ fontSize: 17 }} />
                        </Button>

                    </Grid>

                    <Grid item xs={1}></Grid>
                </Grid>

                <div className="cardContainer">

                    <Grid container spacing={10}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={8}>
                            <Paper square style={{ width: '100%', marginTop: 10, borderRadius: 0 }}>
                                <div style={{ overflowX: 'auto', maxHeight: this.state.height }}>
                                    <Table style={{ height: this.state.height }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={styles.head}>
                                                    <div>
                                                        <div style={{ marginTop: 10, width: 60 }}>Category</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell style={styles.head}>
                                                    <Grid container style={{ width: 200 }}>
                                                        <div>
                                                            <div style={{ marginTop: 10, width: 60 }}>School</div>
                                                        </div>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell style={styles.head}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {orderBy(this.state.filteredCategories, this.state.sortBy, this.state.sortDirection)
                                                .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                                                .map((row, index) => {
                                                    return (
                                                        <TableRow key={index} hover style={{ cursor: 'pointer' }}>
                                                            <TableCell onClick={() => this.onCategoryClick(row.category_id)}>{row.category_name}</TableCell>
                                                            <TableCell onClick={() => this.onCategoryClick(row.category_id)}>{row.school_name}</TableCell>
                                                            <TableCell>
                                                                <IconButton>
                                                                    <ArchiveIcon style={{ fontSize: 24, color: '#C9322D' }} onClick={() => this.onArchiveOpen(row.category_id)} />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                                )}

                                            <TableRow>
                                                <TableCell colSpan={6} />
                                            </TableRow>

                                        </TableBody>
                                    </Table>

                                </div>
                                <TablePagination
                                    rowsPerPageOptions={[25]}
                                    component="div"
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.currentPage}
                                    count={this.state.filteredCategories.length}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onPageChange={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>

                </div>

                <Dialog open={this.state.isAddOpen}
                    onClose={this.onNewClose}
                    maxWidth='sm' fullWidth={true}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title"
                        onClose={this.onNewClose}>
                        Create New Category
                    </DialogTitle>

                    <DialogContent>

                        <div>

                            <Grid container style={{ marginTop: 10, marginBottom: 20 }}>

                                <Grid item xs={12}>

                                    <FormControl fullWidth style={{ marginTop: 10 }}>
                                        <InputLabel htmlFor="desc">Category Name</InputLabel>
                                        <Input variant="outline" fullWidth
                                            value={this.state.category_name}
                                            onChange={(event) => this.setState({ category_name: event.target.value })}
                                            id="desc" aria-describedby="my-helper-text" />
                                    </FormControl>

                                </Grid>

                                <Grid item xs={12}>

                                    <FormControl style={{ marginTop: 15, }}
                                        fullWidth>
                                        <InputLabel htmlFor="goal-type">Select School</InputLabel>
                                        <Select variant='standard'
                                            value={this.state.schoolId}
                                            onChange={(event) => this.setState({ schoolId: event.target.value })}
                                            inputProps={{
                                                name: 'goalType',
                                                id: 'goal-type',
                                            }}
                                        >
                                            <MenuItem disabled value={0}>Select School</MenuItem>
                                            {this.renderSchools()}
                                        </Select>
                                    </FormControl>

                                </Grid>

                            </Grid>

                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onNewClick} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>


                {/* Add students modal/dialog below */}
                <Dialog open={this.state.isAddStudentOpen}
                    onClose={this.onNewStudentClose}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title"
                        onClose={this.onNewClose}>
                        Add Students to Categories
                    </DialogTitle>

                    <DialogContent>

                        <div>

                            <Button onClick={() => this.onCheckAll()}>
                                Check all
                            </Button>

                            <Grid item lg={12}>
                                <Typography style={{ fontSize: 18, marginBottom: 10 }}>Select Students</Typography>
                                {this.renderStudents()}

                            </Grid>

                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onNewStudentClick} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.isArchiveOpen}
                    onClose={this.onArchiveClose}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title"
                        onClose={this.onArchiveClose}>
                        Archive Category
                    </DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you would like to archive this category?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.onArchiveClose()} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={() => this.archiveCategory()} color="primary">
                            Archive
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userId: state.auth.userId,
        userName: state.auth.userName,
        token: state.auth.token,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => { dispatch(logout()); },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesScreen);