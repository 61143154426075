import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from "../actions";

export const defaultState = {
    userId: 0,
    username: '',
    password: '',
    personId: 0,
    firstName: '',
    lastName: '',
    token: '',
    userRightId: 0,
    isLoggedIn: false,
    hasError: false,
    isAdmin:''
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case LOGIN_FAILURE:
            return Object.assign({}, state, {
                isLoggedIn: false,
                hasError: action.hasError
            });
        case LOGIN_SUCCESS:
            return Object.assign({}, state, {
                isLoggedIn: true,
                hasError: false,
                userId: action.userId,
                username: action.username,
                password: action.password,
                personId: action.personId,
                firstName: action.firstName,
                lastName: action.lastName,
                userRightId: action.userRightId,
                token: action.token,
                isAdmin: action.isAdmin
            });
        case LOGOUT:
            return Object.assign({}, state, {
                isLoggedIn: false,
                hasError: false,
                userId: 0,
                username: '',
                password: '',
                personId: 0,
                firstName: '',
                lastName: '',
                userRightId: 0,
                token: '',
                disabled:0,
                isAdmin:''
            });
        default:
            return state
    }
}