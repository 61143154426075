import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import TrashIcon from '@mui/icons-material/Delete';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup, Grid, IconButton, Input, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" onClick={onClose}
                    sx={{ position: 'absolute', right: 10, top: 20 }} >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class ActivityScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            selected: false,
            isEditOpen: false,
            isAddStudentOpen: false,
            isAddServiceOpen: false,
            isDeleteServiceOpen: false,
            isDeleteStudentOpen: false,
            rowsPerPage: 9,
            currentPage: 0,
            rowsPerPage2: 9,
            currentPage2: 0,
            checkAll: false,
            services: [],
            editServices: [],
            students: [],
            studentDataSource: [],
            serviceDataSource: [],
            filteredStudentDataSource: [],
            editStudents: [],
            consultants: [],
            schools: [],
            targets: [],
            activityId: 0,
            description: '',
            dateOf: '',
            hours: '',
            schoolId: 0,
            schoolName: '',
            targetId: 0,
            targetName: '',
            consultantId: 0,
            coConsultantId: 0,
            firstName: '',
            lastName: '',
            coFirstName: '',
            coLastName: ''
        };

        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.onAddStudentClose = this.onAddStudentClose.bind(this);
        this.onAddServiceClose = this.onAddServiceClose.bind(this);
        this.onDeleteStudentClose = this.onDeleteStudentClose.bind(this);
        this.onDeleteServiceClose = this.onDeleteServiceClose.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onEditOpen = this.onEditOpen.bind(this);
        this.onEditClose = this.onEditClose.bind(this);
    }

    updateDimensions() {
        let update_height = window.innerHeight;
        this.setState({ height: update_height });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    componentDidMount() {

        window.addEventListener("resize", this.updateDimensions.bind(this));

        this.getData();

        this.updateDimensions();
    }

    getData() {

        fetch(process.env.REACT_APP_API_URL + '/activity/' + this.props.match.params.activityId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    isLoading: false,

                    activityId: responseJson.activities[0].activity_id,
                    description: responseJson.activities[0].description,
                    dateOf: responseJson.activities[0].date_of,
                    hours: responseJson.activities[0].hours,
                    numberOfParents: responseJson.activities[0].number_of_parents,
                    schoolId: responseJson.activities[0].school_id,
                    schoolName: responseJson.activities[0].school_name,
                    targetId: responseJson.activities[0].target_id,
                    targetName: responseJson.activities[0].target_name,
                    consultantId: responseJson.activities[0].consultant_id,
                    firstName: responseJson.activities[0].first_name,
                    lastName: responseJson.activities[0].last_name,
                    coConsultantId: responseJson.activities[0].co_consultant_id,
                    coFirstName: responseJson.activities[0].co_first_name,
                    coLastName: responseJson.activities[0].co_last_name,
                    services: responseJson.activities[0].services,
                    students: responseJson.activities[0].students
                });
            })

        fetch(process.env.REACT_APP_API_URL + '/consultants', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    isLoading: false,
                    consultants: responseJson.consultants
                });
            })

        fetch(process.env.REACT_APP_API_URL + '/targets', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    isLoading: false,
                    targets: responseJson.targets
                });
            })
            .catch((error) => {

            });

        fetch(process.env.REACT_APP_API_URL + '/schools', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                this.setState({
                    isLoading: false,
                    schools: responseJson.schools
                })
            })
            .catch((error) => {

            });
    }

    getServices() {

        fetch(process.env.REACT_APP_API_URL + '/services', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                this.setState({
                    isLoading: false,
                    serviceDataSource: responseJson.services
                });

                var editServices = [];

                if (responseJson.services !== undefined) {
                    for (let i = 0; i < responseJson.services.length; i++) {
                        editServices['choice_' + responseJson.services[i].service_id] = 0;
                    }
                }

                this.setState({
                    editServices: editServices
                })
            })
            .catch((error) => {

            });
    }

    getStudents(school_id) {

        fetch(process.env.REACT_APP_API_URL + '/students/school/' + school_id, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                this.setState({
                    isLoading: false,
                    studentDataSource: responseJson.students,
                    filteredStudentDataSource: responseJson.students
                });

                var editStudents = [];

                if (responseJson.students !== undefined) {
                    for (let i = 0; i < responseJson.students.length; i++) {
                        editStudents['choice_' + responseJson.students[i].student_id] = 0;
                    }
                }

                this.setState({
                    editStudents: editStudents
                })

            })
            .catch((error) => {

            });
    }

    addStudent() {

        var students = []

        for (let i = 0; i < this.state.studentDataSource.length; i++) {
            if (this.state.editStudents['choice_' + this.state.studentDataSource[i].student_id] === 1) {
                students.push(this.state.studentDataSource[i].student_id);
            }
        }

        fetch(process.env.REACT_APP_API_URL + '/activities/student', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                students: students,
                activity_id: this.state.editActivityId
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {

                    this.setState({
                        isLoading: false,
                        isAddStudentOpen: false
                    })

                    this.getData();
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    deleteStudent() {

        fetch(process.env.REACT_APP_API_URL + '/activities/student', {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                activity_id: this.state.activityId,
                student_id: this.state.selectedItem.student_id
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {

                    this.setState({
                        isLoading: false,
                        isDeleteStudentOpen: false
                    })

                    this.getData();
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    addService() {

        var services = []

        for (let i = 0; i < this.state.serviceDataSource.length; i++) {
            if (this.state.editServices['choice_' + this.state.serviceDataSource[i].service_id] === 1) {
                services.push(this.state.serviceDataSource[i].service_id);
            }
        }

        fetch(process.env.REACT_APP_API_URL + '/activities/service', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                services: services,
                activity_id: this.state.editActivityId
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {

                    this.setState({
                        isLoading: false,
                        isAddServiceOpen: false
                    })

                    this.getData();
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    deleteService() {

        fetch(process.env.REACT_APP_API_URL + '/activities/service', {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                activity_id: this.state.activityId,
                service_id: this.state.selectedItem.service_id
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {

                    this.setState({
                        isLoading: false,
                        isDeleteServiceOpen: false
                    })

                    this.getData();
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    handleChangePage(event, newPage) {
        this.setState({
            currentPage: newPage
        })
    }

    handleChangeRowsPerPage(event) {
        this.setState({
            rowsPerPage: event.target.value
        })
    }

    onDeleteServiceOpen(item) {
        this.setState({
            selectedItem: item,
            isDeleteServiceOpen: true
        })
    }

    onDeleteServiceClose() {
        this.setState({
            isDeleteServiceOpen: false
        })
    }

    onDeleteStudentOpen(item) {
        this.setState({
            selectedItem: item,
            isDeleteStudentOpen: true
        })
    }

    onDeleteStudentClose() {
        this.setState({
            isDeleteStudentOpen: false
        })
    }

    saveActivity() {

        fetch(process.env.REACT_APP_API_URL + '/activities', {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                date_of: this.state.editDateOf,
                description: this.state.editDescription,
                hours: this.state.editHours,
                school_id: this.state.editSchoolId,
                consultant_id: this.state.editConsultantId,
                co_consultant_id: this.state.editCoConsultantId,
                target_id: this.state.editTargetId,
                number_of_parents: this.state.editNumberOfParents,
                activity_id: this.state.editActivityId
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {

                    this.setState({
                        isLoading: false,
                        isEditOpen: false
                    })

                    this.getData();
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    updateSearch = (e) => {
        this.setState({
            search: e.target.value.toUpperCase()
        })
    }

    renderActivity() {

        return (

            <Grid container>
                <Grid item xs={12}>
                    <div style={{ position: 'sticky' }} className="lds-dual-ring" id="hideMe"></div>
                    <Paper square style={{ marginTop: 10, borderRadius: 0 }}>
                        <div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <div>
                                                <div style={{ marginTop: 10, width: 60 }}>Consultant</div>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                <div style={{ marginTop: 10, width: 70 }}>Co-Consultant</div>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <Grid>
                                                <div>
                                                    <div style={{ marginTop: 10, width: 60 }}>School</div>
                                                </div>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid>
                                                <div>
                                                    <div style={{ marginTop: 10, width: 60 }}>Target</div>
                                                </div>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid>
                                                <div>
                                                    <div style={{ marginTop: 10, width: 60 }}>Hours</div>
                                                </div>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid>
                                                <div>
                                                    <div style={{ marginTop: 10, width: 60 }}>Parents</div>
                                                </div>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid>
                                                <div>
                                                    <div style={{ marginTop: 10, width: 60 }}>Date</div>
                                                </div>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    <TableRow hover style={{ cursor: 'pointer' }}>
                                        <TableCell>{this.state.firstName + " " + this.state.lastName}</TableCell>
                                        <TableCell>{this.state.coFirstName === null ? "" : this.state.coFirstName + " " + this.state.coLastName}</TableCell>
                                        <TableCell>{this.state.schoolName}</TableCell>
                                        <TableCell>{this.state.targetName}</TableCell>
                                        <TableCell>{this.state.hours}</TableCell>
                                        <TableCell>{this.state.numberOfParents}</TableCell>
                                        <TableCell>{moment.utc(this.state.dateOf).format("MM/DD/YYYY")}</TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        )
    }


    renderTargets() {

        var content = this.state.targets.map((item, key) => {
            return (
                <MenuItem key={key} value={item.target_id}>{item.target_name}</MenuItem>
            )
        })

        return content;

    }

    renderSchools() {

        var content = this.state.schools.map((item, key) => {
            return (
                <MenuItem key={key} value={item.school_id}>{item.school_name}</MenuItem>
            )
        })

        return content;

    }

    renderConsultants() {

        var content = this.state.consultants.map((item, key) => {
            return (
                <MenuItem key={key} value={item.consultant_id}>{item.first_name} {item.last_name}</MenuItem>
            )
        })

        return content;

    }

    onDateChange(date) {
        this.setState({
            editDateOf: date
        })
    }

    onEditOpen() {
        this.setState({
            isEditOpen: true,
            editDateOf: this.state.dateOf,
            editDescription: this.state.description,
            editHours: this.state.hours,
            editSchoolId: this.state.schoolId,
            editConsultantId: this.state.consultantId,
            editCoConsultantId: this.state.coConsultantId,
            editTargetId: this.state.targetId,
            editNumberOfParents: this.state.numberOfParents,
            editActivityId: this.state.activityId
        })
    }

    onEditClose() {
        this.setState({
            isEditOpen: false
        })
    }

    onAddStudentOpen() {
        this.setState({
            editActivityId: this.state.activityId,
            editStudents: [],
            isAddStudentOpen: true
        })

        this.getStudents(this.state.schoolId);
    }

    onAddStudentClose() {
        this.setState({
            isAddStudentOpen: false
        })
    }

    onAddServiceOpen() {
        this.setState({
            editActivityId: this.state.activityId,
            editServices: [],
            isAddServiceOpen: true
        })

        this.getServices(this.state.schoolId);
    }

    onAddServiceClose() {
        this.setState({
            isAddServiceOpen: false
        })
    }

    updateStudentSearch = (e) => {

        var searchText = e.target.value.toUpperCase();

        let filteredStudents = this.state.studentDataSource.filter((student) => {

            let fullName = student.first_name + " " + student.last_name

            return fullName.toUpperCase().indexOf(searchText) !== -1;
        }
        );

        this.setState({
            filteredStudentDataSource: filteredStudents,
            studentSearch: searchText,
        })
    }

    renderStudents() {

        var content = this.state.filteredStudentDataSource.map((item, key) => {
            return (

                <Grid key={key} item xs={4}>
                    <FormControl component="fieldset">
                        <FormGroup>
                            <FormControlLabel
                                onClick={() => this.onStudentClick(item.student_id)}
                                control={<Checkbox color='default' checked={this.state.editStudents['choice_' + item.student_id] ? true : false} />}
                                label={<TableRow>
                                    <TableCell style={{ width: 150 }}>{item.first_name + " " + item.last_name}</TableCell>
                                    <TableCell>{item.grade_id}th Grade</TableCell>
                                </TableRow>}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
            )
        })

        return content;

    }

    renderServices() {

        var content = this.state.serviceDataSource.map((item, key) => {
            return (
                <Grid key={key} item xs={4} style={{ margin: 0, padding: 0 }}>
                    <FormControl component="fieldset" style={{ margin: 0, padding: 0 }}>
                        <FormGroup button onClick={() => this.onServiceClick(item.service_id)}>
                            <FormControlLabel
                                control={<Checkbox color='default' checked={this.state.editServices['choice_' + item.service_id]} />}
                                label={item.service_name}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>

            )
        })

        return content;

    }

    onStudentClick(studentId) {

        this.setState({
            showValidation: false
        })

        var editStudents = this.state.editStudents;


        for (let i = 0; i < this.state.studentDataSource.length; i++) {
            if (this.state.studentDataSource[i].student_id === studentId
                && (editStudents['choice_' + this.state.studentDataSource[i].student_id] === 0
                    || editStudents['choice_' + this.state.studentDataSource[i].student_id] === undefined)) {
                editStudents['choice_' + this.state.studentDataSource[i].student_id] = 1;
            }
            else if (this.state.studentDataSource[i].student_id === studentId) {
                editStudents['choice_' + this.state.studentDataSource[i].student_id] = 0;
            }
        }

        this.setState({
            editStudents: editStudents
        });
    }

    onServiceClick(serviceId) {

        this.setState({
            showValidation: false
        })

        var editServices = this.state.editServices;

        for (let i = 0; i < this.state.serviceDataSource.length; i++) {
            if (this.state.serviceDataSource[i].service_id === serviceId
                && (editServices['choice_' + this.state.serviceDataSource[i].service_id] === 0
                    || editServices['choice_' + this.state.serviceDataSource[i].service_id] === undefined)) {
                editServices['choice_' + this.state.serviceDataSource[i].service_id] = 1;
            }
            else if (this.state.serviceDataSource[i].service_id === serviceId) {
                editServices['choice_' + this.state.serviceDataSource[i].service_id] = 0;
            }
        }

        this.setState({
            editServices: editServices
        });
    }

    onCheckAll() {

        var checkAll = !this.state.checkAll;

        var editStudents = this.state.editStudents;

        for (let i = 0; i < this.state.studentDataSource.length; i++) {
            editStudents['choice_' + this.state.studentDataSource[i].student_id] = checkAll == true ? true : false;
        }

        this.setState({
            checkAll: checkAll,
            editStudents: editStudents
        });
    }

    render() {

        const { classes } = this.props;

        return (
            <div>

                <Dialog open={this.state.isAddStudentOpen}
                    onClose={this.onAddStudentClose}
                    maxWidth='lg'
                    fullWidth='true'
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title"
                        onClose={this.onAddStudentClose}>
                        Add Student
                    </DialogTitle>
                    <DialogContent>
                        <Grid>
                            <div style={{ display: 'flex', justifyContent: 'row', position: 'sticky' }}>

                                <Typography style={{ fontSize: 18, marginBottom: 10, paddingTop: 10 }}>Select Students</Typography>

                                <input
                                    value={this.state.studentSearch}
                                    onChange={this.updateStudentSearch.bind(this)}
                                    style={{ backgroundColor: '#f7f7f7', border: 'none', borderRadius: 5, alignContent: 'center', paddingRight: 55, paddingLeft: 20, paddingTop: 2, paddingBottom: 2, marginRight: 20, marginLeft: 20, fontSize: 14, letterSpacing: 2 }}
                                    type="text"
                                    placeholder="SEARCH" />

                                <Button onClick={() => this.onCheckAll()}
                                    style={{ color: '#FFF', fontWeight: 'bold', backgroundColor: '#C9322D', marginRight: 20 }}>
                                    {this.state.checkAll ? 'UnCheck All' : 'Check All'}
                                </Button>
                            </div>

                            <hr />

                            <div style={{ flexGrow: 1, overflowX: 'auto' }}>
                                <Grid container style={{ marginLeft: 20, height: 400 }}>
                                    {this.renderStudents()}
                                </Grid>
                            </div>

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.onAddStudentClose()}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.addStudent()}>
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.isAddServiceOpen}
                    onClose={this.onAddServiceClose}
                    maxWidth='lg'
                    fullWidth='true'
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title"
                        onClose={this.onAddServiceClose}>
                        Add Service
                    </DialogTitle>
                    <DialogContent>
                        <Grid>

                            <div style={{ flexGrow: 1, overflowX: 'auto' }}>
                                <Grid container spacing={4} style={{ margin: 0, padding: 0, height: 300 }}>
                                    {this.renderServices()}
                                </Grid>
                            </div>

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.onAddServiceClose()} >
                            Cancel
                        </Button>
                        <Button onClick={() => this.addService()} >
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.isDeleteStudentOpen}
                    onClose={this.onDeleteStudentClose}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title"
                        onClose={this.onDeleteStudentClose}>
                        Remove Student
                    </DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you would like to remove this student?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.onDeleteStudentClose()}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.deleteStudent()}>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.isDeleteServiceOpen}
                    onClose={this.onDeleteServiceClose}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title"
                        onClose={this.onDeleteServiceClose}>
                        Remove Service
                    </DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you would like to remove this service?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.onDeleteServiceClose()}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.deleteService()}>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.isEditOpen}
                    maxWidth='md'
                    fullWidth='true'
                    onClose={this.onEditClose}
                    aria-labelledby="form-dialog-title">

                    <DialogTitle onClose={this.onEditClose}>
                        Edit Activity
                    </DialogTitle>
                    <DialogContent>

                        <Grid container spacing={2}>

                            <Grid item xs={6}>
                                <FormControl variant="standard" style={{ width: '100%' }}>
                                    <InputLabel htmlFor="consultant">Consultant</InputLabel>
                                    <Select
                                        value={this.state.editConsultantId}
                                        onChange={(event) => this.setState({ editConsultantId: event.target.value })}>

                                        <MenuItem disabled value={0}>Choose Consultant</MenuItem>

                                        {this.renderConsultants()}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl variant="standard" style={{ width: '100%' }}>
                                    <InputLabel htmlFor="coConsultant">Co-Consultant</InputLabel>
                                    <Select
                                        value={this.state.editCoConsultantId}
                                        onChange={(event) => this.setState({ editCoConsultantId: event.target.value })}>

                                        <MenuItem value={0}>Choose Consultant</MenuItem>

                                        {this.renderConsultants()}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>

                                <FormControl variant="standard" style={{ width: '100%' }}>
                                    <InputLabel htmlFor="goal-type">School</InputLabel>
                                    <Select
                                        value={this.state.editSchoolId}
                                        onChange={(event) => this.setState({ editSchoolId: event.target.value })}>
                                        {this.renderSchools()}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl variant="standard" style={{ width: '100%' }}>
                                    <InputLabel htmlFor="goal-type">Target Audience</InputLabel>
                                    <Select
                                        value={this.state.editTargetId}
                                        onChange={(event) => this.setState({ editTargetId: event.target.value })}>
                                        {this.renderTargets()}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl style={{ width: '100%' }}>
                                    <InputLabel htmlFor="desc">Description</InputLabel>
                                    <Input variant="filled"
                                        multiline={true}
                                        rows="4"
                                        value={this.state.editDescription}
                                        onChange={(event) => this.setState({ editDescription: event.target.value })}
                                        id="desc" aria-describedby="my-helper-text" />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl variant="standard" style={{ marginTop: 15, width: '100%' }} >
                                    <LocalizationProvider dateAdapter={AdapterMoment}>

                                        <DesktopDatePicker style={{ marginTop: 0, paddingTop: 0 }}

                                            format={"MM/DD/YYYY"}
                                            value={moment.utc(this.state.editDateOf).format("MM/DD/YYYY")}
                                            onChange={(date) => this.onDateChange(date)}
                                            renderInput={(params) => <TextField variant="standard" {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>

                            </Grid>

                            <Grid item xs={6}>

                                <FormControl style={{ width: '100%' }}>
                                    <InputLabel htmlFor="hours">Hours</InputLabel>
                                    <Input variant="filled"
                                        value={this.state.editHours}
                                        onChange={(event) => this.setState({ editHours: event.target.value })}
                                        id="hours" aria-describedby="my-helper-text" />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl style={{ width: '100%' }}>
                                    <InputLabel htmlFor="desc">Number of Parents</InputLabel>
                                    <Input variant="filled"
                                        value={this.state.editNmberOfParents}
                                        onChange={(event) => this.setState({ editNumberOfParents: event.target.value })}
                                        id="desc" aria-describedby="my-helper-text" />
                                </FormControl>
                            </Grid>


                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.onEditClose()}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.saveActivity()}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                <h1 className="title">Activity</h1>

                <Grid container style={{ marginBottom: 10 }}>
                    <Grid item xs={8}>

                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <Button onClick={() => this.onEditOpen()} style={{ color: '#FFF', fontWeight: 'bold', backgroundColor: '#C9322D', marginRight: 20 }}>
                            <Typography style={{ fontFamily: 'Josefin Sans', paddingRight: 10, paddingTop: 3 }}>Edit Activity</Typography>
                            <AddCircleIcon style={{ fontSize: 17 }}>Add</AddCircleIcon>
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                </Grid>

                <Grid container spacing={6}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>{this.renderActivity()}</Grid>
                    <Grid item xs={2}></Grid>
                </Grid>

                <Grid container spacing={6}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={4}>

                        <Paper square style={{ marginTop: 10, borderRadius: 0 }}>
                            <div className={classes.tableWrapper} style={{ height: 300 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.head}>
                                                <div>
                                                    <div style={{ marginTop: 10, width: 60 }}>Student</div>
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.head} style={{ textAlign: 'right' }}>
                                                <IconButton style={{ margin: 0, padding: 5 }} onClick={() => this.onAddStudentOpen()}>
                                                    <AddIcon style={{ fontSize: 20, color: '#C9322D' }} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {orderBy(this.state.students, this.state.sortBy, this.state.sortDirection)
                                            .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                                            .map((row, index) => {
                                                return (
                                                    <TableRow key={index} hover style={{ cursor: 'pointer' }}>
                                                        <TableCell style={{ width: 250 }}>{row.student_first_name + ' ' + row.student_last_name}</TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            <IconButton style={{ margin: 0, padding: 5 }} onClick={() => this.onDeleteStudentOpen(row)} >
                                                                <TrashIcon style={{ fontSize: 20, color: '#C9322D' }} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }
                                            )}

                                    </TableBody>
                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[10]}
                                component="div"
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.currentPage}
                                count={this.state.students.length}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onPageChange={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </Paper>

                    </Grid>
                    <Grid item xs={4}>
                        <Grid container >
                            <Grid item xs={12}>
                                <Paper square style={{ marginTop: 10, borderRadius: 0 }}>
                                    <div>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <div>
                                                            <div style={{ width: 60 }}>Description</div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                <TableRow hover style={{ cursor: 'pointer' }}>
                                                    <TableCell>{this.state.description}</TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 10 }}>

                                <Paper square style={{ margin: 0, padding: 0, borderRadius: 0 }}>
                                    <div>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <div>
                                                            <div style={{ marginTop: 10, width: 60 }}>Service</div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.head} style={{ textAlign: 'right' }}>
                                                        <IconButton style={{ margin: 0, padding: 5 }} onClick={() => this.onAddServiceOpen()}>
                                                            <AddIcon style={{ fontSize: 20, color: '#C9322D' }} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {orderBy(this.state.services, this.state.sortBy2, this.state.sortDirection2)
                                                    .slice(this.state.currentPage2 * this.state.rowsPerPage2, this.state.currentPage2 * this.state.rowsPerPage2 + this.state.rowsPerPage2)
                                                    .map((row, index) => {
                                                        return (

                                                            <TableRow key={index} hover style={{ cursor: 'pointer' }}>
                                                                <TableCell>{row.service_name}</TableCell>
                                                                <TableCell style={{ textAlign: 'right' }}>
                                                                    <IconButton style={{ margin: 0, padding: 5 }} onClick={() => this.onDeleteServiceOpen(row)}>
                                                                        <TrashIcon style={{ fontSize: 20, color: '#C9322D' }}></TrashIcon>
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                    )}

                                            </TableBody>
                                        </Table>
                                    </div>
                                </Paper>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            </div>
        );
    }
}

ActivityScreen.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
    return {
        userId: state.auth.userId,
        userName: state.auth.userName,
        token: state.auth.token,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => { dispatch(logout()); },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ActivityScreen));