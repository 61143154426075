import { combineReducers } from 'redux';
import activity from './activity';
import auth from './auth';
import progress from './progress';
 
const rootReducer = combineReducers({
    auth,
    activity,
    progress
});
 
export default rootReducer;