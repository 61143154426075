import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout } from '../../../redux/actions/auth';
//import Idle from 'react-idle'


class LoggedOutScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      route: 'LoggedOutScreen',
    }
  }

  onLogout() {
    this.props.onLogout();
    this.props.history.push('/');
  }


  render() {

    const { classes, ...other } = this.props;

    return (
      <div className="root">
        {/* <Idle
                timeout={420000}
                render={({ idle }) =>
                <h1 style={{marginTop:100}}>
                    {idle
                    ? this.props.onLogout()
                    : " "
                    }
                </h1>
                }
            /> */}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    firstName: state.auth.firstName,
    lastName: state.auth.lastName,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => {
      dispatch(logout());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoggedOutScreen));