import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import './style.css';

class ProfileScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      route: 'ProfileScreen',
      refreshing: false,
      isLoading: true,
      dataSource: [],
      isEditOpen: false,
      selectedItem: {}
    };
  }

  componentDidMount() {

  }

  userLogout(e) {
    this.props.onLogout();
    e.preventDefault();
  }

  render() {


    return (
      <div>
        <React.Fragment >
          <h1 className="title">Profile</h1>

        </React.Fragment>
      </div>
    );

  }

}


const mapStateToProps = (state, ownProps) => {
  return {
    userId: state.auth.userId,
    userName: state.auth.userName,
    token: state.auth.token,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);