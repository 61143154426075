import AddCircleIcon from '@mui/icons-material/AddCircle';
import BlockIcon from '@mui/icons-material/Block';
import PencilIcon from '@mui/icons-material/Create';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Button, Card, CardActions, CardContent, Checkbox, Dialog, DialogActions, DialogContentText,
  DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, TextField, Typography
} from '@mui/material';
import Switch from '@mui/material/Switch';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import './style.css';


class UserScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      route: 'UserScreen',
      refreshing: false,
      isLoading: true,
      dataSource: [],
      disabledAccounts: [],
      selectedItem: {},
      checkedB: false,
      isAddOpen: false,
      isDisableOpen: false,
      isReactivateOpen: false,
      isEditActiveOpen: false,
      isEditDisabledOpen: false,
      first_name: "",
      last_name: "",
      user_name: "",
      user_id: 0,
      password: "",
      disabled: 0,
      checked: false,
      clickedItemId: 0,
      viewDisabled: false,
      search: '',
      isAdmin: 0,
      isAdminText: "",
      onPasswordOpen: false
    };
  }

  componentDidMount() {
    this.getData()
  }

  updateSearch = (e) => {
    this.setState({
      search: e.target.value.toUpperCase()
    })
  }

  getData = () => {
    let disabledUsers = [];
    let notDisabledUsers = [];
    fetch(process.env.REACT_APP_API_URL + '/users', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.Users.forEach(function (user) {
          if (user.disabled === 1) {
            disabledUsers.push(user)
          } else {
            notDisabledUsers.push(user)
          }
        })
        this.setState({
          dataSource: notDisabledUsers,
          disabledAccounts: disabledUsers,
          isLoading: false
        })
      })
  }

  userLogout(e) {
    this.props.onLogout();
    e.preventDefault();
  }

  onReactivateOpen = (clickedItem) => {
    this.setState({
      clickedItemId: clickedItem.user_id,
      first_name: clickedItem.first_name,
      last_name: clickedItem.last_name,
      user_name: clickedItem.user_name,
      user_id: clickedItem.user_id,
      isReactivateOpen: true
    })
  }

  onReactivateClose = (item) => {
    this.setState({
      isReactivateOpen: false,
      selectedItem: item
    })
  }

  onDisableOpen = (clickedItem) => {
    this.setState({
      clickedItemId: clickedItem.user_id,
      first_name: clickedItem.first_name,
      last_name: clickedItem.last_name,
      user_name: clickedItem.user_name,
      user_id: clickedItem.user_id,
      isDisableOpen: true
    })
  }

  onDisableClose = (item) => {
    this.setState({
      isDisableOpen: false,
      selectedItem: item
    })
  }

  onDisableUser = () => {
    this.setState({
      isLoading: true
    })

    fetch(process.env.REACT_APP_API_URL + '/users/disable', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: "omit",

      body: JSON.stringify({
        password: this.state.password,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        disabled: 1,
        user_name: this.state.user_name,
        user_id: this.state.user_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.getData();
        this.setState({
          isLoading: false,
          isDisableOpen: false
        })
      })
  }

  onReactivateUser = () => {
    this.setState({
      isLoading: true
    })

    fetch(process.env.REACT_APP_API_URL + '/users/disable', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: "omit",

      body: JSON.stringify({
        password: this.state.password,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        disabled: 0,
        user_name: this.state.user_name,
        user_id: this.state.user_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.getData();
        this.setState({
          isLoading: false,
          isReactivateOpen: false
        })
      })
  }

  onEditActiveOpen = (i) => {
    this.setState({
      first_name: i.first_name,
      last_name: i.last_name,
      user_name: i.user_name,
      password: i.password,
      user_id: i.user_id,
      disabled: this.state.disabled,
      isEditActiveOpen: true,
    })
  }

  onEditDisabledOpen = (i) => {

    this.setState({
      first_name: i.first_name,
      last_name: i.last_name,
      user_name: i.user_name,
      password: i.password,
      user_id: i.user_id,
      disabled: this.state.disabled,
      isEditDisabledOpen: true,
    })
  }

  onPasswordOpen = () => {
    this.setState({
      onPasswordOpen: true,
    })
  }

  onPasswordClose = () => {
    this.setState({
      onPasswordOpen: false,
    })
  }

  onEditClose = () => {
    this.setState({
      isEditDisabledOpen: false,
      isEditActiveOpen: false
    })
  }

  onAddOpen = () => {
    this.setState({
      isAddOpen: true
    })
  }

  onAddClose = () => {
    this.setState({
      isAddOpen: false
    })
  }

  //  postToConsultant = () => (
  //   fetch(process.env.REACT_APP_API_URL + '/consultants', {
  //     method: "POST",
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //       'token': this.props.token
  //     },
  //     credentials: 'omit',

  //     body: JSON.stringify({
  //       user_name:this.state.user_name,
  //       password:this.state.password,
  //       first_name:this.state.first_name,
  //       last_name:this.state.last_name
  //     })
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       this.postToConsultant()
  //       this.getData()
  //       this.setState({
  //         isLoading: false,
  //         isAddOpen:false
  //       });

  //     })
  //     .catch((error) => {
  //       this.setState({
  //         isLoading: false
  //       })
  //       this.props.onLogout();
  //     });
  //  )

  onAddUser = () => {
    fetch(process.env.REACT_APP_API_URL + '/users', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',

      body: JSON.stringify({
        user_name: this.state.user_name,
        password: this.state.password,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        disabled: 0,
        is_admin: this.state.isAdmin
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.getData()
        this.setState({
          isLoading: false,
          isAddOpen: false
        });

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        this.props.onLogout();
      });
  }

  onEditActiveUser = () => {

    this.setState({
      isLoading: true
    })

    fetch(process.env.REACT_APP_API_URL + '/users', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: "omit",

      body: JSON.stringify({
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        disabled: this.state.disabled,
        user_name: this.state.user_name,
        user_id: this.state.user_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.getData();
          this.setState({
            isLoading: false,
            isEditActiveOpen: false
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onEditPassword = () => {
    this.setState({
      isLoading: true
    })

    fetch(process.env.REACT_APP_API_URL + '/users/password', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: "omit",

      body: JSON.stringify({
        password: this.state.password,
        user_id: this.state.user_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.getData();
          this.setState({
            isLoading: false,
            onPasswordOpen: false,
            isEditDisabledOpen: false,
            isEditActiveOpen: false
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onEditDisabledUser = () => {

    this.setState({
      isLoading: true
    })

    fetch(process.env.REACT_APP_API_URL + '/users', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: "omit",

      body: JSON.stringify({
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        disabled: 1,
        user_name: this.state.user_name,
        user_id: this.state.user_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.getData();
          this.setState({
            isLoading: false,
            isEditDisabledOpen: false
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  handleSwitch = name => event => {
    this.setState({ [name]: event.target.checked });

    if (event.target.checked === true) {
      this.setState({
        viewDisabled: true
      })
    } else {
      this.setState({
        viewDisabled: false
      })
    }
  };

  handleFirstNameChange = (e) => {

    this.setState({
      first_name: e.target.value
    });
  }

  handleLastNameChange = (e) => {

    this.setState({
      last_name: e.target.value
    });
  }

  handleUsernameChange = (e) => {

    this.setState({
      user_name: e.target.value
    });
  }

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value
    });
  }

  handleIsAdminChangeYes = (e) => {
    this.setState({ isAdmin: "Yes" })
  }

  handleIsAdminChangeNo = (e) => {
    this.setState({ isAdmin: "No" })
  }

  renderDisabledUsers() {

    let filteredDisabledUsers = this.state.disabledAccounts.filter(
      (user) => {

        let fullName = user.first_name + " " + user.last_name

        return fullName.toUpperCase().indexOf(this.state.search) !== -1;
      }
    );

    if (filteredDisabledUsers === undefined) return null;

    var content = filteredDisabledUsers.map((item, key) => {

      return (

        <Card key={key} style={{ margin: 20, maxHeight: 220, minWidth: 420 }}>
          <CardContent>
            <CardActions>
              <Button variant="contained" style={{ marginLeft: 320 }}
                onClick={(evt) => this.onEditDisabledOpen(item)}>
                <PencilIcon style={{ fontSize: 17 }}>Edit</PencilIcon>
              </Button>
            </CardActions>
            {/* Consultant */}
            <Typography gutterBottom style={{ paddingTop: 0 }}>
              <strong>Name:</strong> {item.first_name} {item.last_name}
            </Typography>

            <Typography gutterBottom style={{ paddingTop: 0 }}>
              <strong>Email | Username:</strong> {item.user_name}
            </Typography>

            <Typography gutterBottom style={{ paddingTop: 0 }}>
              <strong>Admin:</strong> {item.is_admin}
            </Typography>



            <Button style={{ marginLeft: 320 }} onClick={() => this.onReactivateOpen(item)} >
              <SyncIcon style={{ fontSize: 17 }} >Activate</SyncIcon>
            </Button>
            <Typography style={{ fontSize: 10, marginLeft: 310 }}>Reactivate account</Typography>

          </CardContent>

        </Card>
      )
    });
    return content;
  }

  renderActivity() {

    let filteredUsers = this.state.dataSource.filter(
      (user) => {

        let fullName = user.first_name + " " + user.last_name

        return fullName.toUpperCase().indexOf(this.state.search) !== -1;
      }
    );

    if (filteredUsers === undefined) return null;

    var content = filteredUsers.map((item, key) => {

      return (

        <Card style={{ margin: 20, maxHeight: 220, minWidth: 420 }}>
          <CardContent>
            <CardActions>
              <Button style={{ marginLeft: 320 }} onClick={() => this.onEditActiveOpen(item)}>
                <PencilIcon style={{ fontSize: 17 }}>Edit</PencilIcon >
              </Button>
            </CardActions>
            {/* Consultant */}
            <Typography gutterBottom style={{ paddingTop: 0 }}>
              <strong>Name:</strong> {item.first_name} {item.last_name}
            </Typography>

            <Typography gutterBottom style={{ paddingTop: 0 }}>
              <strong>Email | Username:</strong> {item.user_name}
            </Typography>

            <Typography gutterBottom style={{ paddingTop: 0 }}>
              <strong>Admin:</strong> {item.is_admin}
            </Typography>


            <Button style={{ marginLeft: 320 }} onClick={() => this.onDisableOpen(item)}>
              <BlockIcon style={{ fontSize: 17 }} >Disable</BlockIcon >
            </Button>
            <Typography style={{ fontSize: 10, marginLeft: 315 }}>Disable account</Typography>

          </CardContent>

        </Card>
      )
    });
    return content;
  }

  render() {

    return (
      <div>
        {/* Add Dialog Below  */}
        <Dialog open={this.state.isAddOpen}
          maxWidth="sm"
          fullWidth={false}
          style={{ position: 'absolute', top: 50, padding: 150, minHeight: '90vh', maxHeight: '90vh', }}
        >

          <DialogTitle id="customized-dialog-title">
            Add a new user to GearUp
          </DialogTitle>


          <Grid container style={{ marginTop: 10, marginBottom: 20 }}>


            <div style={{ flex: 1, flexDirection: 'row' }}>
              <FormControl style={{ marginTop: -15, }}
                fullWidth>
                <TextField
                  id="standard-dense3"
                  label="First name"
                  style={{ marginLeft: 30, width: 200 }}
                  margin="dense"
                  onChange={(event) => this.handleFirstNameChange(event)}
                />
                <TextField
                  id="standard-dense4"
                  label="Last name"
                  style={{ marginLeft: 30, width: 200 }}
                  margin="dense"
                  onChange={(event) => this.handleLastNameChange(event)}
                />

                <TextField
                  id="standard-helperText"
                  label="Email"
                  style={{ marginLeft: 30, width: 200, marginTop: 0 }}
                  helperText="*This email address will be used as the accounts username"
                  margin="normal"
                  autoComplete="new-email"
                  onChange={(event) => this.handleUsernameChange(event)}
                />

                <TextField
                  id="standard-password-input"
                  label="Password"
                  style={{ marginLeft: 30, width: 200, marginTop: 0 }}
                  helperText="*This password can be changed or reset at any time"
                  type="password"
                  margin="normal"
                  autoComplete="new-password"
                  onChange={(event) => this.handlePasswordChange(event)}
                />


                <Typography style={{ marginLeft: 30, marginTop: 10 }}>Is this user an Admin?</Typography>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                  <FormControl component="fieldset" style={{ marginLeft: 30 }}>
                    <FormGroup onChange={(event) => this.handleIsAdminChangeYes(event)}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Yes"
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl component="fieldset" style={{ marginLeft: 30 }}>
                    <FormGroup onChange={(event) => this.handleIsAdminChangeNo(event)}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="No"
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </FormControl>
            </div>

          </Grid>


          <DialogActions>
            <Button onClick={this.onAddClose} >
              Cancel
            </Button>
            <Button onClick={this.onAddUser} >
              Add user
            </Button>
          </DialogActions>

        </Dialog>
        {/*  */}

        {/* Change Password Below  */}
        <Dialog open={this.state.onPasswordOpen}
          maxWidth="sm"
          fullWidth={false}
          style={{ padding: 150 }}
        >

          <DialogTitle id="customized-dialog-title">
            Enter your new password
          </DialogTitle>

          <TextField
            id="standard-password-input"
            style={{ marginLeft: 30, width: 200, marginTop: 0 }}
            // helperText="*Enter new password"
            type="password"
            autoComplete="new-password"
            margin="normal"
            onChange={(event) => this.handlePasswordChange(event)}
          />

          <DialogActions>
            <Button onClick={this.onPasswordClose}>
              Cancel
            </Button>
            <Button onClick={this.onEditPassword}>
              Save changes
            </Button>
          </DialogActions>

        </Dialog>


        {/* Edit Active user Dialog Below  */}
        <Dialog open={this.state.isEditActiveOpen}
          maxWidth="sm"
          fullWidth={false}
          style={{ padding: 150 }}
        >

          <DialogTitle id="customized-dialog-title">
            Edit a GearUp user
          </DialogTitle>


          <Grid container style={{ marginTop: 10, marginBottom: 10 }}>


            <div style={{ flex: 1, flexDirection: 'row' }}>
              <FormControl style={{ marginTop: 15, }}
                fullWidth
                autoComplete="off">
                <TextField
                  id="standard-dense"
                  value={this.state.first_name}
                  style={{ marginLeft: 30, width: 200, paddingRight: 40 }}
                  margin="dense"
                  onChange={(event) => this.handleFirstNameChange(event)}
                />
                <TextField
                  id="standard-dense2"
                  value={this.state.last_name}
                  style={{ marginLeft: 30, width: 200 }}
                  margin="dense"
                  onChange={(event) => this.handleLastNameChange(event)}
                />

                <TextField
                  id="standard-helperText"
                  value={this.state.user_name}
                  style={{ marginLeft: 30, width: 200, marginTop: 0 }}
                  helperText="*Enter new email address"
                  margin="normal"
                  autoComplete="new-email"
                  onChange={(event) => this.handleUsernameChange(event)}
                />

                {/* <TextField
                                id="standard-password-input"
                                placeholder="Enter a new password"
                                value={this.state.password}
                                style={{marginLeft:30, width:200, marginTop:0}}
                                // helperText="*Enter new password"
                                type="password"
                                autoComplete="new-password"
                                margin="normal"
                                onChange={(event) => this.handlePasswordChange(event)}
                        /> */}

                <Button color='default' style={{ marginTop: 10 }} onClick={this.onPasswordOpen}>
                  Change password
                </Button>
              </FormControl>
            </div>
          </Grid>


          <DialogActions>
            <Button onClick={this.onEditClose}>
              Cancel
            </Button>
            <Button onClick={this.onEditActiveUser}>
              Save changes
            </Button>
          </DialogActions>

        </Dialog>
        {/*  */}

        {/* Edit Dialog Below  */}
        <Dialog open={this.state.isEditDisabledOpen}
          maxWidth="sm"
          fullWidth={false}
          style={{ padding: 150 }}
        >

          <DialogTitle id="customized-dialog-title">
            Edit a GearUp user
          </DialogTitle>


          <Grid container style={{ marginTop: 10, marginBottom: 20 }}>


            <div style={{ flex: 1, flexDirection: 'row' }}>
              <FormControl style={{ marginTop: 15, }}
                fullWidth
                autoComplete="off">
                <TextField
                  id="standard-dense"
                  value={this.state.first_name}
                  style={{ marginLeft: 30, width: 200, paddingRight: 40 }}
                  margin="dense"
                  onChange={(event) => this.handleFirstNameChange(event)}
                />
                <TextField
                  id="standard-dense2"
                  value={this.state.last_name}
                  style={{ marginLeft: 30, width: 200 }}
                  margin="dense"
                  onChange={(event) => this.handleLastNameChange(event)}
                />

                <TextField
                  id="standard-helperText"
                  value={this.state.user_name}
                  style={{ marginLeft: 30, width: 200, marginTop: 0 }}
                  helperText="*Enter new email address"
                  margin="normal"
                  autoComplete="new-email"
                  onChange={(event) => this.handleUsernameChange(event)}
                />

                {/* <TextField
                                id="standard-password-input"
                                placeholder="Enter a new password"
                                value={this.state.password}
                                style={{marginLeft:30, width:200, marginTop:0}}
                                // helperText="*Enter new password"
                                type="password"
                                autoComplete="new-password"
                                margin="normal"
                                onChange={(event) => this.handlePasswordChange(event)}
                        /> */}

                <Button color='default' style={{ marginTop: 10 }} onClick={this.onPasswordOpen}>
                  Change password
                </Button>
              </FormControl>
            </div>

          </Grid>


          <DialogActions>
            <Button onClick={this.onEditClose}>
              Cancel
            </Button>
            <Button onClick={this.onEditDisabledUser}>
              Save changes
            </Button>
          </DialogActions>

        </Dialog>
        {/*  */}

        {/* Disable Dialog below */}
        <Dialog open={this.state.isDisableOpen}
          maxWidth="sm"
          fullWidth={false}
          style={{ padding: 150 }}
        >
          <DialogTitle>The account "{this.state.user_name}" will be disabled. <br></br> Are you sure you want to disable this user?</DialogTitle>
          <DialogContentText style={{ paddingLeft: 20 }}>*You can reactivate this user again at any time*</DialogContentText>
          <DialogActions>
            <Button onClick={this.onDisableClose}>
              Cancel
            </Button>
            <Button onClick={this.onDisableUser}>
              Disable
            </Button>
          </DialogActions>
        </Dialog>

        {/* Reactivate Dialog below */}
        <Dialog open={this.state.isReactivateOpen}
          maxWidth="sm"
          fullWidth={false}
          style={{ padding: 150 }}
        >
          <DialogTitle>The account "{this.state.user_name}" is currently disabled. <br></br> Are you sure you want to reactivate this user?</DialogTitle>
          <DialogContentText style={{ paddingLeft: 20 }}>*You can disable this user again at any time*</DialogContentText>
          <DialogActions>
            <Button onClick={this.onReactivateClose}>
              Cancel
            </Button>
            <Button onClick={this.onReactivateUser}>
              Reactivate
            </Button>
          </DialogActions>
        </Dialog>

        <React.Fragment >
          <h1 className="title">Users</h1>


          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap', }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Switch
                checked={this.state.checkedB}
                onClick={this.handleSwitch('checkedB')}
                value="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <p style={{ fontFamily: 'Josefin Sans', fontSize: 13, letterSpacing: 2 }} >Click to view disabled users</p>
            </div>

            <input
              type="text"
              value={this.state.search}
              onChange={this.updateSearch.bind(this)}
              style={{
                backgroundColor: '#f7f7f7', border: 'none', borderRadius: 5, alignContent: 'center',
                paddingRight: 55, paddingLeft: 20, paddingTop: -20, paddingBottom: -20, fontFamily: 'Josefin Sans',
                fontSize: 14, letterSpacing: 2
              }}
              placeholder="Search" />

            <Button onClick={(item) => this.onAddOpen(item)} style={{ marginRight: 150 }}>
              <Typography style={{ fontFamily: 'Josefin Sans', paddingRight: 10, paddingTop: 3 }}>Add user</Typography>
              <AddCircleIcon style={{ fontSize: 17 }}>Add</AddCircleIcon>
            </Button>
          </div>
          <div className="cardContainer">

            {!this.state.viewDisabled ? this.renderActivity() : this.renderDisabledUsers()}

          </div>
        </React.Fragment>
      </div>
    );

  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    userId: state.auth.userId,
    userName: state.auth.userName,
    token: state.auth.token,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserScreen);