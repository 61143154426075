export default {

    viewFlex: {
        flex: 5,
    },
    tableWrapper: {
        overflowX: 'auto'
    },
    head: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 20
    }
  };