import { GET_FORM, SET_FORM, RESET_FORM, GET_DETAIL } from "../actions";

export const defaultState = {
    date_of: '',
    description: '',
    schoolId: 0,
    consultant_id: 0,
    co_consultant_id: 0,
    targetId: 0,
    serviceId: 0,
    hours: 0,
    studentId: [],
 };
 
 export default function reducer(state = defaultState, action) {
     switch (action.type) {
         case GET_FORM:
             return Object.assign({}, state, {
             });
         case RESET_FORM:
             return Object.assign({}, state, {
                date_of: '',
                description: '',
                schoolId: 0,
                consultant_id: 0,
                co_consultant_id: 0,
                target_id: 0,
                service_id: 0,
                hours: 0,
                studentId: 0,
             });
         case SET_FORM: 
             return Object.assign({}, state, {
                date_of: action.date_of,
                description: action.description,
                schoolId: action.schoolId,
                consultant_id: action.consultant_id,
                co_consultant_id: action.co_consultant_id,
                targetId: action.targetId,
                serviceId: action.serviceId,
                hours: action.hours,
                studentId: action.studentId,
             });
             case GET_DETAIL:
                return Object.assign({}, state, {
                   activity_id: action.activity_id,
                });
         default:
             return state
     }
 }