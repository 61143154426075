import AddCircleIcon from '@mui/icons-material/AddCircle';
import DownArrow from '@mui/icons-material/ArrowDropDown';
import UpArrow from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import TrashIcon from '@mui/icons-material/Delete';
import {
  Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import styles from './style';
import './style.css';

const invertSort = {
  asc: "desc",
  desc: "asc"
}

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class StudentScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      route: 'StudentScreen',
      refreshing: false,
      isLoading: true,
      dataSource: [],
      height: 100,
      rowsPerPage: 25,
      currentPage: 0,
      schoolId: 0,
      filteredDataSource: [],
      schools: [],
      grades: [],
      ethnicities: [],
      isEditOpen: false,
      selectedItem: {},
      isAddOpen: false,
      isDeleteOpen: false,
      isCategoryOpen: false,
      student_number: "",
      gender: "",
      ethnicity: "",
      school_id: "",
      first_name: "",
      last_name: "",
      limited_english: false,
      individualized_learning_program: false,
      homeless: false,
      foster_care: false,
      selectedItemId: 0,
      searchText: '',
      sortBy: '',
      sortDirection: 'desc'
    };

    this.onSearch = this.onSearch.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.onDeleteClose = this.onDeleteClose.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
    this.onAddClose = this.onAddClose.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.getData();

    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    let update_height = window.innerHeight - 345;
    this.setState({ height: update_height });
  }

  updateSearch = (e) => {
    this.setState({
      search: e.target.value.toUpperCase()
    })
  }

  getData() {

    this.getStudents();

    fetch(process.env.REACT_APP_API_URL + '/schools', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          schools: responseJson.schools
        });
      })
      .catch((error) => {

      });

    fetch(process.env.REACT_APP_API_URL + '/ethnicities', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          ethnicities: responseJson.ethnicities
        });
      })
      .catch((error) => {

      });

    fetch(process.env.REACT_APP_API_URL + '/grades', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          grades: responseJson.grades
        });
      })
      .catch((error) => {

      });

  }

  getStudents() {
    fetch(process.env.REACT_APP_API_URL + '/students', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        console.log(responseJson);

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.students,
            filteredDataSource: responseJson.students
          });
        }

        this.onSearch(this.state.searchText, this.state.schoolId)
      })

      .catch((error) => {
        this.setState({
          isLoading: false,
          dataSource: [],
          filteredDataSource: []
        })
        this.props.onLogout();
      });
  }

  onEditStudent() {

    fetch(process.env.REACT_APP_API_URL + '/students', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',

      body: JSON.stringify({
        student_number: this.state.editStudentNumber,
        first_name: this.state.editFirstName,
        last_name: this.state.editLastName,
        ethnicity: this.state.editEthnicity,
        gender: this.state.editGender,
        school_id: this.state.editSchoolId,
        grade_id: this.state.editGradeId,
        limited_english: this.state.editLimitedEnglish,
        individualized_learning_program: this.state.editIndividualizedLearningProgram,
        homeless: this.state.editHomeless,
        foster_care: this.state.editFosterCare,
        student_id: this.state.editStudentId
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.getStudents();

        this.setState({
          isLoading: false,
          isEditOpen: false
        });

      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isEditOpen: false
        })
        this.props.onLogout();
      });
  }

  userLogout(e) {
    this.props.onLogout();
    e.preventDefault();
  }

  onAddOpen = () => {
    this.setState({
      isAddOpen: true,
      student_number: "",
      first_name: "",
      last_name: "",
      ethnicity: "",
      gender: "",
      school_id: "",
      grade_id: "",
      limited_english: false,
      individualized_learning_program: false,
      homeless: false,
      foster_care: false
    })
  }

  onAddClose() {
    this.setState({
      isAddOpen: false
    })
  }

  onEditClose() {
    this.setState({
      isEditOpen: false
    })
  }

  onCategoryOpen = () => {
    this.setState({
      isCategoryOpen: true
    })
  }

  onDeleteOpen = (item) => {
    this.setState({
      selectedItem: item,
      isDeleteOpen: true
    })
  }

  onEditOpen(item) {

    this.setState({
      isEditOpen: true,
      editStudentNumber: item.student_number,
      editFirstName: item.first_name,
      editLastName: item.last_name,
      editEthnicity: item.ethnicity,
      editGender: item.gender,
      editSchoolId: item.school_id,
      editGradeId: item.grade_id,
      editLimitedEnglish: item.limited_english,
      editIndividualizedLearningProgram: item.individualized_learning_program,
      editHomeless: item.homeless,
      editFosterCare: item.foster_care,
      editStudentId: item.student_id
    })

  }

  onDeleteClose() {
    this.setState({
      isDeleteOpen: false
    })
  }

  onDeleteUserCategory = () => {
    fetch(process.env.REACT_APP_API_URL + '/student/category', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        student_id: this.state.selectedItem.student_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {

          this.onDeleteUserActivity();
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onDeleteUserActivity = () => {
    fetch(process.env.REACT_APP_API_URL + '/student/activity', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        student_id: this.state.selectedItem.student_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {

          this.onDeleteUser();
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onDeleteUser = () => {
    fetch(process.env.REACT_APP_API_URL + '/students/delete', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        student_id: this.state.selectedItem.student_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.getStudents();

        this.setState({
          isLoading: false,
          isDeleteOpen: false,
          selectedItem: {}
        })

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onAddStudent() {
    fetch(process.env.REACT_APP_API_URL + '/students', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',

      body: JSON.stringify({
        student_number: this.state.student_number,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        ethnicity: this.state.ethnicity,
        gender: this.state.gender,
        school_id: this.state.school_id,
        grade_id: this.state.grade_id,
        limited_english: this.state.limited_english,
        individualized_learning_program: this.state.individualized_learning_program,
        homeless: this.state.homeless,
        foster_care: this.state.foster_care
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.getStudents();

        this.setState({
          isLoading: false,
          isAddOpen: false
        });

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        this.props.onLogout();
      });
  }

  renderSchools() {

    var content = this.state.schools.map((item, key) => {
      return (
        <MenuItem key={key} value={item.school_id}>{item.school_name}</MenuItem>
      )
    })
    return content;
  }

  renderGrades() {

    var content = this.state.grades.map((item, key) => {
      return (
        <MenuItem key={key} value={item.grade_id}>{item.grade_name}</MenuItem>
      )
    })
    return content;
  }

  renderEthnicity() {

    var content = this.state.ethnicities.map((item, key) => {
      return (
        <MenuItem key={key} value={item.ethnicity_id}>{item.ethnicity_name}</MenuItem>
      )
    })
    return content;
  }

  onSearch = (searchText, schoolId) => {

    const newData = this.state.dataSource.filter(function (item) {
      const nameData = item.first_name.toUpperCase() + " " + item.last_name.toUpperCase()
      const schoolData = item.school_id;
      const searchData = searchText.toUpperCase()

      if (searchData === "" && schoolId !== 0) {
        return schoolData === schoolId ? true : false
      } else if (searchData !== "" && schoolId === 0) {
        return nameData.indexOf(searchData) > -1
      } else if (searchData !== "" && schoolId !== 0) {
        return nameData.indexOf(searchData) > -1 && schoolData === schoolId ? true : false
      } else {
        return true;
      }

    })
    this.setState({
      filteredDataSource: newData,
      searchText: searchText,
      schoolId: schoolId
    })
  }

  handleChangePage(event, newPage) {
    this.setState({
      currentPage: newPage
    })
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: event.target.value
    })
  }

  handleSort = (col) => {
    this.setState(state => ({
      sortBy: col,
      sortDirection: state.sortBy === col ? invertSort[state.sortDirection] : 'asc'
    }));
  }

  render() {
    return (
      <div>

        <Dialog open={this.state.isAddOpen}
          onClose={this.onAddClose} maxWidth={'lg'}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onAddClose}>
            Add Student
          </DialogTitle>

          <DialogContent>

            <Grid container spacing={2} style={{ marginTop: 10 }}>

              <Grid item xs={12} >

                <FormControl fullWidth>
                  <TextField
                    id="standard-dense3"
                    label="Student Number"
                    margin="dense"
                    onChange={(event) => this.setState({ student_number: event.target.value })}
                  />
                </FormControl>

              </Grid>

              <Grid item xs={6} style={{ marginTop: 10 }}>

                <FormControl fullWidth>
                  <TextField
                    id="standard-dense3"
                    label="First name"
                    margin="dense"
                    onChange={(event) => this.setState({ first_name: event.target.value })}
                  />
                </FormControl>

              </Grid>

              <Grid item xs={6} style={{ marginTop: 10 }}>

                <FormControl fullWidth>
                  <TextField
                    id="standard-dense4"
                    label="Last name"
                    margin="dense"
                    onChange={(event) => this.setState({ last_name: event.target.value })}
                  />
                </FormControl>

              </Grid>

              <Grid item xs={6} style={{ marginTop: 10 }}>

                <FormControl fullWidth>
                  <InputLabel htmlFor="school-simple">School</InputLabel>

                  <Select
                    value={this.state.school_id} fullWidth
                    onChange={(event) => this.setState({ school_id: event.target.value })}
                    inputProps={{
                      name: 'school',
                      id: 'school-simple',
                    }}>
                    {this.renderSchools()}
                  </Select>
                </FormControl>

              </Grid>

              <Grid item xs={6} style={{ marginTop: 10 }}>

                <FormControl fullWidth>
                  <InputLabel htmlFor="gender-simple">Grade</InputLabel>

                  <Select fullWidth
                    value={this.state.grade_id}
                    onChange={(event) => this.setState({ grade_id: event.target.value })}
                    inputProps={{
                      name: 'gender',
                      id: 'gender-simple'
                    }}>
                    {this.renderGrades()}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} style={{ marginTop: 10 }}>

                <FormControl fullWidth>
                  <InputLabel htmlFor="gender-simple">Gender</InputLabel>

                  <Select fullWidth
                    value={this.state.gender}
                    onChange={(event) => this.setState({ gender: event.target.value })}
                    inputProps={{
                      name: 'gender',
                      id: 'gender-simple'
                    }}>
                    <MenuItem value={"M"}>Male</MenuItem>
                    <MenuItem value={"F"}>Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} style={{ marginTop: 10 }}>

                <FormControl fullWidth>
                  <InputLabel htmlFor="ethnicity-simple">Ethnicity</InputLabel>

                  <Select
                    value={this.state.ethnicity} fullWidth
                    onChange={(event) => this.setState({ ethnicity: event.target.value })}
                    inputProps={{
                      name: 'ethnicity',
                      id: 'ethnicity-simple',
                    }}>

                    {this.renderEthnicity()}

                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} style={{ marginTop: 20 }}>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.limited_english}
                      onChange={(event) => this.setState({ limited_english: event.target.checked })}
                      inputProps={{ 'aria-label': 'primary checkbox' }} />
                  }
                  label="Limited English"
                />

              </Grid>

              <Grid item xs={6} style={{ marginTop: 20 }}>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.individualized_learning_program}
                      onChange={(event) => this.setState({ individualized_learning_program: event.target.checked })}
                      inputProps={{ 'aria-label': 'primary checkbox' }} />
                  }
                  label="Individualized Learning Plan"
                />

              </Grid>

              <Grid item xs={6} style={{ marginTop: 10 }}>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.homeless}
                      onChange={(event) => this.setState({ homeless: event.target.checked })}
                      inputProps={{ 'aria-label': 'primary checkbox' }} />
                  }
                  label="Homeless"
                />

              </Grid>

              <Grid item xs={6} style={{ marginTop: 10 }}>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.foster_care}
                      onChange={(event) => this.setState({ foster_care: event.target.checked })}
                      inputProps={{ 'aria-label': 'primary checkbox' }} />
                  }
                  label="Foster Care"
                />

              </Grid>

            </Grid>

          </DialogContent>


          <DialogActions>
            <Button variant="contained" onClick={() => this.onAddClose()}>
              Cancel
            </Button>

            <Button variant="contained" onClick={() => this.onAddStudent()} style={{
              color: '#FFF',
              fontWeight: 'bold', backgroundColor: '#C9322D'
            }}>
              <Typography style={{ fontFamily: 'Josefin Sans', paddingRight: 10, paddingTop: 3 }}> Add Student</Typography>
              <AddCircleIcon style={{ fontSize: 17 }} />
            </Button>

          </DialogActions>

        </Dialog>

        <Dialog open={this.state.isEditOpen}
          onClose={this.onEditClose} fullWidth
          maxWidth={'lg'}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onEditClose}>
            Edit Student
          </DialogTitle>

          <DialogContent>

            <Grid container spacing={2} style={{ marginTop: 10 }}>

              <Grid item xs={12}>

                <FormControl fullWidth>
                  <TextField
                    id="standard-dense3"
                    label="Student Number"
                    value={this.state.editStudentNumber}
                    margin="dense"
                    onChange={(event) => this.setState({ editStudentNumber: event.target.value })}
                  />
                </FormControl>

              </Grid>

              <Grid item xs={6} style={{ marginTop: 10 }}>

                <FormControl fullWidth>
                  <TextField
                    id="standard-dense3"
                    label="First Name"
                    value={this.state.editFirstName}
                    margin="dense"
                    onChange={(event) => this.setState({ editFirstName: event.target.value })}
                  />
                </FormControl>

              </Grid>

              <Grid item xs={6} style={{ marginTop: 10 }}>

                <FormControl fullWidth>
                  <TextField
                    id="standard-dense4"
                    label="Last Name"
                    margin="dense"
                    value={this.state.editLastName}
                    onChange={(event) => this.setState({ editLastName: event.target.value })}
                  />
                </FormControl>

              </Grid>

              <Grid item xs={6} style={{ marginTop: 10 }}>

                <FormControl fullWidth>
                  <InputLabel htmlFor="school-simple">School</InputLabel>

                  <Select
                    value={this.state.editSchoolId} fullWidth
                    onChange={(event) => this.setState({ editSchoolId: event.target.value })}
                    inputProps={{
                      name: 'school',
                      id: 'school-simple',
                    }}>
                    {this.renderSchools()}
                  </Select>
                </FormControl>

              </Grid>

              <Grid item xs={6} style={{ marginTop: 10 }}>

                <FormControl fullWidth>
                  <InputLabel htmlFor="gender-simple">Grade</InputLabel>

                  <Select fullWidth
                    value={this.state.editGradeId}
                    onChange={(event) => this.setState({ editGradeId: event.target.value })}
                    inputProps={{
                      name: 'gender',
                      id: 'gender-simple'
                    }}>
                    {this.renderGrades()}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} style={{ marginTop: 10 }}>

                <FormControl fullWidth>
                  <InputLabel htmlFor="gender-simple">Gender</InputLabel>

                  <Select fullWidth
                    value={this.state.editGender}
                    onChange={(event) => this.setState({ editGender: event.target.value })}
                    inputProps={{
                      name: 'gender',
                      id: 'gender-simple'
                    }}>
                    <MenuItem value={"M"}>Male</MenuItem>
                    <MenuItem value={"F"}>Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} style={{ marginTop: 10 }}>

                <FormControl fullWidth>
                  <InputLabel htmlFor="ethnicity-simple">Ethnicity</InputLabel>

                  <Select
                    value={this.state.editEthnicity} fullWidth
                    onChange={(event) => this.setState({ editEthnicity: event.target.value })}
                    inputProps={{
                      name: 'ethnicity',
                      id: 'ethnicity-simple',
                    }}>

                    {this.renderEthnicity()}

                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} style={{ marginTop: 20 }}>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.editLimitedEnglish}
                      onChange={(event) => this.setState({ editLimitedEnglish: event.target.checked })}
                      inputProps={{ 'aria-label': 'primary checkbox' }} />
                  }
                  label="Limited English"
                />

              </Grid>

              <Grid item xs={6} style={{ marginTop: 20 }}>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.editIndividualizedLearningProgram}
                      onChange={(event) => this.setState({ editIndividualizedLearningProgram: event.target.checked })}
                      inputProps={{ 'aria-label': 'primary checkbox' }} />
                  }
                  label="Individualized Learning Plan"
                />

              </Grid>

              <Grid item xs={6} style={{ marginTop: 10 }}>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.editHomeless}
                      onChange={(event) => this.setState({ editHomeless: event.target.checked })}
                      inputProps={{ 'aria-label': 'primary checkbox' }} />
                  }
                  label="Homeless"
                />

              </Grid>

              <Grid item xs={6} style={{ marginTop: 10 }}>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.editFosterCare}
                      onChange={(event) => this.setState({ editFosterCare: event.target.checked })}
                      inputProps={{ 'aria-label': 'primary checkbox' }} />
                  }
                  label="Foster Care"
                />

              </Grid>

            </Grid>

          </DialogContent>


          <DialogActions>

            <Button variant="contained" onClick={() => this.onEditClose()}>
              Cancel
            </Button>

            <Button variant="contained" onClick={() => this.onEditStudent()} style={{
              color: '#FFF',
              fontWeight: 'bold', backgroundColor: '#C9322D'
            }}>
              <Typography style={{ fontFamily: 'Josefin Sans', paddingRight: 10, paddingTop: 3 }}> Save Student</Typography>
              <AddCircleIcon style={{ fontSize: 17 }} />
            </Button>

          </DialogActions>

        </Dialog>

        <Dialog open={this.state.isDeleteOpen}
          maxWidth="sm" fullWidth close={this.onDeleteClose}
          style={{ padding: 150 }}>
          <DialogTitle onClose={this.onDeleteClose}>
            Delete Student
          </DialogTitle>

          <DialogContent>
            <Typography>Are you sure you want to delete this student?</Typography>
            <Typography style={{ marginTop: 20 }}>The student "{this.state.selectedItem.first_name} {this.state.selectedItem.last_name}" will be deleted.</Typography>

          </DialogContent>

          <DialogActions>
            <Button onClick={() => this.onDeleteClose()} >
              Cancel
            </Button>
            <Button onClick={this.onDeleteUserCategory}
              style={{ color: '#FFF', backgroundColor: '#C9322D' }}>
              Delete Student
            </Button>
          </DialogActions>
        </Dialog>


        <h1 className="title">Students</h1>

        <Grid container>

          <Grid item xs={1}></Grid>

          <Grid item xs={4}>

            <TextField style={{ width: '55%' }}
              value={this.state.searchText}
              onChange={(event) => this.onSearch(event.target.value, this.state.schoolId)}
              type="text"
              placeholder="Search" />

          </Grid>
          <Grid item xs={3}>
            <FormControl style={{ margin: 0, padding: 0 }}>
              <Select style={{ width: 300 }}
                labelId="filter_school"
                id="filter_school_list"
                value={this.state.schoolId}
                onChange={(event) => this.onSearch(this.state.searchText, event.target.value)}>
                <MenuItem value={0}>All Schools</MenuItem>
                {this.renderSchools()}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3} style={{ textAlign: 'right' }}>

            <Button onClick={() => this.onAddOpen()} style={{
              color: '#FFF', fontWeight: 'bold',
              backgroundColor: '#C9322D'
            }}>
              <Typography style={{ fontFamily: 'Josefin Sans', paddingRight: 10, paddingTop: 3 }}>Add Student</Typography>
              <AddCircleIcon style={{ fontSize: 17 }}>Add</AddCircleIcon>
            </Button>

          </Grid>
        </Grid>

        <div className="cardContainer">

          <Grid container spacing={10}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Paper square style={{ width: '100%', marginTop: 10, borderRadius: 0 }}>
                <div style={{ overflowX: 'auto', maxHeight: this.state.height }}>
                  <Table style={{ height: this.state.height }}>
                    <TableHead>
                      <TableRow>
                        <TableCell width={100} style={styles.head}>
                          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("student_number")}>
                            Student Number
                            {this.state.sortBy === "student_number" ? (
                              this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                            ) : null}
                          </div>
                        </TableCell>
                        <TableCell width={200} style={styles.head}>

                          <Grid container>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("first_name")}>
                              {this.state.sortBy === "first_name" ? (
                                <div style={{ marginTop: 10, fontWeight: 'bold', width: 63 }}>First Name</div>
                              ) : <div style={{ marginTop: 10, width: 75 }}>First Name</div>
                              }
                              {this.state.sortBy === "first_name" ? (
                                this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}> <UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /></div>
                              ) : null}
                            </div>
                            {this.state.sortBy === "first_name" ?
                              <Typography style={{ marginTop: 10, marginLeft: 10, marginRight: 5 }}>/</Typography>
                              : (this.state.sortBy === "last_name" ? <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>
                                : <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>)
                            }
                            <div style={{ display: 'flex', width: 90, alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("last_name")}>
                              {this.state.sortBy === "last_name" ? (
                                <div style={{ marginTop: 10, fontWeight: 'bold' }}>Last Name</div>
                              ) : <div style={{ marginTop: 10 }}>Last Name</div>
                              }
                              {this.state.sortBy === "last_name" ? (
                                this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /> </div>
                              ) : null}
                            </div>
                          </Grid>
                        </TableCell>
                        <TableCell width={250} style={styles.head}>
                          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("school_name")}>
                            School Name
                            {this.state.sortBy === "school_name" ? (
                              this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                            ) : null}
                          </div>
                        </TableCell>
                        <TableCell width={100} style={styles.head}>
                          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("grade_name")}>
                            Grade
                            {this.state.sortBy === "grade_name" ? (
                              this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                            ) : null}
                          </div>
                        </TableCell>
                        <TableCell width={25} style={styles.head}>
                          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("gender")}>
                            Gender
                            {this.state.sortBy === "gender" ? (
                              this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                            ) : null}
                          </div>
                        </TableCell>
                        <TableCell width={200} style={styles.head}>
                          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("ethnicity_name")}>
                            Ethnicity
                            {this.state.sortBy === "ethnicity_name" ? (
                              this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                            ) : null}
                          </div>
                        </TableCell>
                        <TableCell width={25} style={styles.head}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {orderBy(this.state.filteredDataSource, this.state.sortBy, this.state.sortDirection)
                        .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                        .map((row, index) => {
                          return (
                            <TableRow key={index} hover style={{ cursor: 'pointer' }}>
                              <TableCell onClick={() => this.onEditOpen(row)}>{row.student_number}</TableCell>
                              <TableCell onClick={() => this.onEditOpen(row)}>{row.first_name + ' ' + row.last_name}</TableCell>
                              <TableCell onClick={() => this.onEditOpen(row)}>{row.school_name}</TableCell>
                              <TableCell onClick={() => this.onEditOpen(row)}>{row.grade_name}</TableCell>
                              <TableCell onClick={() => this.onEditOpen(row)}>{row.gender}</TableCell>
                              <TableCell onClick={() => this.onEditOpen(row)}>{row.ethnicity_name}</TableCell>
                              <TableCell>
                                <IconButton onClick={() => this.onDeleteOpen(row)}>
                                  <TrashIcon style={{ fontSize: 24, color: '#C9322D' }} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        }
                        )}

                      <TableRow>
                        <TableCell colSpan={6} />
                      </TableRow>

                    </TableBody>
                  </Table>

                </div>
                <TablePagination
                  rowsPerPageOptions={[25]}
                  component="div"
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.currentPage}
                  count={this.state.filteredDataSource.length}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onPageChange={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Paper>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>

        </div>

      </div>);

  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userId: state.auth.userId,
    userName: state.auth.userName,
    token: state.auth.token,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StudentScreen));