export default {

    closeButton: {
        position: 'absolute',
        right: 10,
        top: 20
    },
    tableWrapper: {
        overflowX: 'auto'
    },
    head: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0
      },
}