import { GET_PROGRESS, SET_PROGRESS } from "../actions";

export const getProgress = () => {
   return {
       type: GET_PROGRESS
   }
};

export const setProgress = (progress) => {
    return {
        type: SET_PROGRESS,
        progress: progress
    }
 };