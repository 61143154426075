import { FormControl, Grid, Input, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { login } from '../../redux/actions/auth';
import './style.css';

class LoginScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      isDialogOpen: false
    };
  }

  userLogin = (e, click) => {
    if (e.charCode === 13 || click) {
      this.props.onLogin(this.state.username, this.state.password);
    }
  }

  renderLogoImage() {
    return null;
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  dialogOpen = () => {
    this.setState({
      isDialogOpen: true
    })
  }

  dialogClose = () => {
    this.setState({
      isDialogOpen: false
    })
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  render() {

    return (
      <Grid container spacing={0} alignItems="center"
        justify="center" style={{ textAlign: 'center', backgroundColor: '#fffff', paddingTop: 120 }}>

        <Grid item xs={12} alignItems="center" justify="center">
          <img width="150" style={{ marginTop: 40 }} src={require('../../assets/launcher.png')} alt="GearUp" />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 40 }}>
          <a className="buttonDl" target="_blank" rel="noopener noreferrer" href="http://www.wkgearup.com/resources.html">
            View Our <strong>Learning Resources</strong>
          </a>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 40 }}>
          or
        </Grid>


        {this.props.hasError ?

          <Grid item xs={12} style={{ marginTop: 40, textAlign: 'center' }}>
            <Typography style={{ fontWeight: 'bold', color: '#000', textAlign: 'center' }}>
              Your email or password is incorrect.
              <br></br>
              Please try again.
            </Typography>

          </Grid>

          : null
        }

        <Grid item xs={12} alignItems="center" justify="center" >

          <FormControl style={{ textAlign: 'center' }}>
            <Input id="input-with-icon-adornment" label="Email Address"
              placeholder="Email"
              className="inputs"
              autoFocus={true}
              variant="outlined"
              error={this.props.hasError}
              onChange={this.handleChange('username')} />
          </FormControl>

        </Grid>

        <Grid item xs={12} alignItems="center" justify="center" >

          <FormControl style={{ textAlign: 'center' }}>
            <Input label="Password"
              placeholder="Password"
              id="adornment-password"
              error={this.props.hasError}
              variant="outlined"
              className={"inputs"}
              type={this.state.showPassword ? 'text' : 'password'}
              onChange={this.handleChange('password')}
              onKeyPress={(e) => this.userLogin(e, false)} />
          </FormControl>


          <div style={{ marginTop: 20, textAlign: 'center' }} >
            <button className="button" onClick={(e) => this.userLogin(e, true)}>
              Log in
            </button>
            {/* <AwesomeButton type="primary">Primary</AwesomeButton> */}
          </div>

        </Grid>

      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    hasError: state.auth.hasError,
    errorMessage: state.auth.errorMessage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (username, password) => { dispatch(login(username, password)); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginScreen));