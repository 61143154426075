import { Button, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import { setProgress } from '../../redux/actions/progress';
import './style.css';

class ReportScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      route: 'StudentScreen',
      refreshing: false,
      isLoading: true,
      students: [],
      participation: [],
      schools: [],
      total: [],
      totalEthnicity: 0,
      ethnicity: [],
      gender: [],
      totalGender: [],
      limitedEnglish: [],
      learningProgram: [],
      homeless: [],
      fosterCare: [],
      parents: [],
      services: [],
      isEditOpen: false,
      selectedItem: {},
      isAddOpen: false,
      isDeleteOpen: false,
      school_id: "",
      first_name: "",
      last_name: "",
      selectedItemId: 0,
      search: '',
      selectedYear: 0,
      selectedSchool: 0,
      years: []
    };
  }

  componentDidMount() {

    this.props.setProgress(0);

    fetch(process.env.REACT_APP_API_URL + '/report/years', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.authenticated) {
          this.setState({
            isLoading: false,
            years: responseJson.years,
            selectedYear: responseJson.selected_year
          });

          this.props.setProgress(100);

          this.getData(0, responseJson.selected_year);
        } else {
          this.props.setProgress(100);
        }
      })
      .catch((error) => {
        this.props.setProgress(100);
      });

    fetch(process.env.REACT_APP_API_URL + '/schools', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          schools: responseJson.schools
        });
      })
      .catch((error) => {

      });
  }

  updateSearch = (e) => {
    this.setState({
      search: e.target.value.toUpperCase()
    })
  }

  getData(selectedSchool, selectedYear) {

    var progress = 0;

    this.props.setProgress(progress);

    fetch(process.env.REACT_APP_API_URL + '/reports/participate/' + selectedSchool + '/' + selectedYear, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          participation: responseJson.results
        });

        this.props.setProgress(100);

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        this.props.onLogout();
      });

    fetch(process.env.REACT_APP_API_URL + '/reports/participate/total/' + selectedSchool + '/' + selectedYear, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          totalProposed: responseJson.results[0].students_proposed,
          totalContinued: responseJson.results[0].students_continued,
          totalNew: responseJson.results[0].students_new,
          totalWithdrew: responseJson.results[0].students_withdrew,
          totalDropped: responseJson.results[0].students_dropped
        });

        this.props.setProgress(100);

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        this.props.onLogout();
      });

    fetch(process.env.REACT_APP_API_URL + '/reports/ethnicity/' + selectedSchool + '/' + selectedYear, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          ethnicity: responseJson.results
        });

        this.props.setProgress(100);

      })
      .catch((error) => {

        this.setState({
          isLoading: false
        })
        this.props.onLogout();
      });

    fetch(process.env.REACT_APP_API_URL + '/reports/ethnicity/total/' + selectedSchool + '/' + selectedYear, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          totalEthnicity: responseJson.total
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        // this.props.onLogout();
      });

    fetch(process.env.REACT_APP_API_URL + '/reports/gender/' + selectedSchool + '/' + selectedYear, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          gender: responseJson.results
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        this.props.onLogout();
      });

    fetch(process.env.REACT_APP_API_URL + '/reports/gender/total/' + selectedSchool + '/' + selectedYear, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          totalGender: responseJson.total
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        // this.props.onLogout();
      });

    fetch(process.env.REACT_APP_API_URL + '/reports/limited_english/' + selectedSchool + '/' + selectedYear, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          limitedEnglish: responseJson.limitedEnglishStudents
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        this.props.onLogout();
      });

    fetch(process.env.REACT_APP_API_URL + '/reports/individualized_learning_program/' + selectedSchool + '/' + selectedYear, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          learningProgram: responseJson.learningProgram
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        this.props.onLogout();
      });

    fetch(process.env.REACT_APP_API_URL + '/reports/homeless/' + selectedSchool + '/' + selectedYear, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          homeless: responseJson.homelessStudents
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        this.props.onLogout();
      });

    fetch(process.env.REACT_APP_API_URL + '/reports/foster_care/' + selectedSchool + '/' + selectedYear, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          fosterCare: responseJson.fosterCareStudents
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        this.props.onLogout();
      });

    fetch(process.env.REACT_APP_API_URL + '/reports/parents/' + selectedSchool + '/' + selectedYear, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          parents: responseJson.parentTotal
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        this.props.onLogout();
      });

    fetch(process.env.REACT_APP_API_URL + '/reports/students/' + selectedSchool + '/' + selectedYear, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          students: responseJson.studentTotal
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        this.props.onLogout();
      });

    fetch(process.env.REACT_APP_API_URL + '/reports/services/' + selectedSchool + '/' + selectedYear, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          services: responseJson.results
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        this.props.onLogout();
      });
  }

  renderParticipation() {

    var content = this.state.participation.map((item, key) => {
      return (
        <TableRow key={key}>
          <TableCell align="right">{item.grade_id}th</TableCell>
          <TableCell align="right">{item.students_proposed}</TableCell>
          <TableCell align="right">{item.students_continued}</TableCell>
          <TableCell align="right">{item.students_new}</TableCell>
          <TableCell align="right">{item.students_withdrew}</TableCell>
          <TableCell align="right">{item.students_dropped}</TableCell>
        </TableRow>
      )
    })
    return content;
  }

  renderTotalParticipation() {

    return (
      <TableRow>
        <TableCell>Total</TableCell>
        <TableCell align="right">{this.state.totalProposed}</TableCell>
        <TableCell align="right">{this.state.totalContinued}</TableCell>
        <TableCell align="right">{this.state.totalNew}</TableCell>
        <TableCell align="right">{this.state.totalWithdrew}</TableCell>
        <TableCell align="right">{this.state.totalDropped}</TableCell>
      </TableRow>
    )
  }

  renderEthnicity() {

    var content = this.state.ethnicity.map((item, key) => {
      if (item.ethnicity_id === 1) {
        return (
          <TableRow key={key}>
            <TableCell>Hispanic or Latino</TableCell>
            <TableCell align="right">{item.total}</TableCell>
          </TableRow>
        )
      }
      else if (item.ethnicity_id === 2) {
        return (
          <TableRow key={key}>
            <TableCell>American Indian or Alaske Native</TableCell>
            <TableCell align="right">{item.total}</TableCell>
          </TableRow>
        )
      }
      else if (item.ethnicity_id === 3) {
        return (
          <TableRow key={key}>
            <TableCell>Asian</TableCell>
            <TableCell align="right">{item.total}</TableCell>
          </TableRow>
        )
      }
      else if (item.ethnicity_id === 4) {
        return (
          <TableRow key={key}>
            <TableCell>Black or African American</TableCell>
            <TableCell align="right">{item.total}</TableCell>
          </TableRow>
        )
      }
      else if (item.ethnicity_id === 5) {
        return (
          <TableRow key={key}>
            <TableCell>Native Hawaiian or other Pacific Islander</TableCell>
            <TableCell align="right">{item.total}</TableCell>
          </TableRow>
        )
      }
      else if (item.ethnicity_id === 6) {
        return (
          <TableRow key={key}>
            <TableCell>White</TableCell>
            <TableCell align="right">{item.total}</TableCell>
          </TableRow>
        )
      }
      else if (item.ethnicity_id === 7) {
        return (
          <TableRow key={key}>
            <TableCell>Two or More Races</TableCell>
            <TableCell align="right">{item.total}</TableCell>
          </TableRow>
        )
      }
    })
    return content;
  }

  renderTotalEthnicity() {

    return (
      <TableRow>
        <TableCell>Total</TableCell>
        <TableCell align="right">{this.state.totalEthnicity}</TableCell>
      </TableRow>
    )
  }

  renderTotalStudents() {

    return (
      <TableRow>
        <TableCell>Total</TableCell>
        <TableCell></TableCell>
        <TableCell align="right">{this.state.total}</TableCell>
      </TableRow>
    )
  }

  renderGender() {
    var content = this.state.gender.map((item, key) => {
      return (
        <TableRow key={key}>
          <TableCell>{item.gender}</TableCell>
          <TableCell align="right">{item.total}</TableCell>
        </TableRow>
      )
    })
    return content;
  }

  renderTotalGender() {

    return (
      <TableRow>
        <TableCell>Total</TableCell>
        <TableCell align="right">{this.state.totalGender}</TableCell>
      </TableRow>
    )
  }

  renderLimitedEnglishStudents() {
    var content = this.state.limitedEnglish.map((item, key) => {
      return (
        <TableRow>
          <TableCell>Limited English</TableCell>
          <TableCell align="right">{item.limited_english_students}</TableCell>
        </TableRow>
      )
    })
    return content;
  }

  renderLearningProgramStudents() {
    var content = this.state.learningProgram.map((item, key) => {
      return (
        <TableRow>
          <TableCell>Individualized Learning Program</TableCell>
          <TableCell align="right">{item.individualized_learning_program_students}</TableCell>
        </TableRow>
      )
    })
    return content;
  }

  renderHomelessStudents() {
    var content = this.state.homeless.map((item, key) => {
      return (
        <TableRow>
          <TableCell>Homeless</TableCell>
          <TableCell align="right">{item.homeless_students}</TableCell>
        </TableRow>
      )
    })
    return content;
  }

  renderFosterCareStudents() {
    var content = this.state.fosterCare.map((item, key) => {
      return (
        <TableRow>
          <TableCell>Foster Care</TableCell>
          <TableCell align="right">{item.foster_care_students}</TableCell>
        </TableRow>
      )
    })
    return content;
  }

  renderParents() {
    return (
      <TableRow>
        <TableCell>Total Number of Parents</TableCell>
        <TableCell align="right">{this.state.parents}</TableCell>
      </TableRow>
    )
  }

  renderStudents() {
    return (
      <TableRow>
        <TableCell>Total Number of Students</TableCell>
        <TableCell align="right">{this.state.students}</TableCell>
      </TableRow>
    )
  }

  renderServices() {
    var content = this.state.services.map((item, key) => {
      return (
        <TableRow key={key}>
          <TableCell align="right">{item.service_name}</TableCell>
          <TableCell align="right">{item.total}</TableCell>
          <TableCell align="right">{item.hours}</TableCell>
          <TableCell align="right">{item.parent_total}</TableCell>
          <TableCell align="right">{item.parent_hours}</TableCell>
        </TableRow>
      )
    })
    return content;
  }

  onYearChange(event) {

    this.getData(this.state.selectedSchool, event.target.value);
    this.setState({ selectedYear: event.target.value })
  }

  onSchoolChange(event) {

    this.getData(event.target.value, this.state.selectedYear);
    this.setState({ selectedSchool: event.target.value })
  }

  exportServices() {

    fetch(process.env.REACT_APP_API_URL + '/exports/services/' + this.state.selectedSchool + '/' + this.state.selectedYear, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        var fileName = "";

        fileName = 'services.csv';

        var link = document.createElement("a");
        link.download = fileName;
        var blob = new Blob([responseJson.results], { type: "text/csv" });
        link.href = URL.createObjectURL(blob);
        link.click();
        URL.revokeObjectURL(link.href);


      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        //  this.props.onLogout();
      });

  }

  render() {

    return (
      <div>
        <React.Fragment >
          <h1 className="title">Reports</h1>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap', }}>
            <Grid style={{ textAlign: 'right' }}>
              <FormControl variant="outlined" style={{ marginTop: 30, marginRight: 30 }}>
                <Select style={{ fontSize: 22 }}
                  value={this.state.selectedSchool}
                  onChange={(event) => this.onSchoolChange(event)}
                  inputProps={{
                    name: 'school',
                    id: 'school',
                  }}
                >
                  <MenuItem value={0}>All Schools</MenuItem>

                  {this.state.schools.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.school_id}>{item.school_name}</MenuItem>
                    )
                  })
                  }
                </Select>
              </FormControl>
              <FormControl variant="outlined" style={{ marginTop: 30 }}>
                <Select style={{ fontSize: 22 }}
                  value={this.state.selectedYear}
                  onChange={(event) => this.onYearChange(event)}
                  inputProps={{
                    name: 'year',
                    id: 'year',
                  }}
                >
                  {this.state.years.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.year_value}>School Year: {item.year_name}</MenuItem>
                    )
                  })
                  }
                </Select>
              </FormControl>
            </Grid>
          </div>

          <div>
            <div className="cardContainer" style={{ display: 'flex', flexDirection: 'column', marginLeft: '20%', marginRight: '20%' }}>
              <div>

                <h3>Number of Participating Students</h3>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Grade Level</TableCell>
                        <TableCell align="right">(a) # of GEAR UP Students Proposed</TableCell>
                        <TableCell align="right">(b) # of Continuing GEAR UP Students</TableCell>
                        <TableCell align="right">(c) # of New GEAR UP Students</TableCell>
                        <TableCell align="right">(d) # of GEAR UP Students who Withdrew</TableCell>
                        <TableCell align="right">(e) # of GEAR UP Students who Dropped Out</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.renderParticipation()}
                      {this.renderTotalParticipation()}
                    </TableBody>
                  </Table>
                </Paper>

              </div>

              <div>

                <h3>Participant Distribution by Ethnic Background</h3>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Race and Ethnicity</TableCell>
                        <TableCell align="right">Number of Unduplicated Students</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.renderEthnicity()}
                      {this.renderTotalEthnicity()}
                    </TableBody>
                  </Table>
                </Paper>

              </div>
              <div>

                <h3>Participation by Gender</h3>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Gender</TableCell>
                        <TableCell align="right">Number of Unduplicated Students Served</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {this.renderGender()}
                      {this.renderTotalGender()}

                    </TableBody>
                  </Table>
                </Paper>
              </div>

              <div>

                <h3>Student Details</h3>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Student Details</TableCell>
                        <TableCell align="right">Number of Unduplicated Students</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {this.renderLimitedEnglishStudents()}
                      {this.renderLearningProgramStudents()}
                      {this.renderHomelessStudents()}
                      {this.renderFosterCareStudents()}

                    </TableBody>
                  </Table>

                </Paper>
              </div>

              <div>

                <Grid container>
                  <Grid item xs={6}>
                    <h3>Activities and Services Summary</h3>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right' }}>

                  </Grid>
                </Grid>


                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Target Details</TableCell>
                        <TableCell align="right">Number of Gearup Participants</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {this.renderParents()}
                      {this.renderStudents()}

                    </TableBody>
                  </Table>

                </Paper>
              </div>

              <div>

                <Grid container>
                  <Grid item xs={6}>
                    <h3>Activities and Services</h3>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Button variant='contained' color="primary" style={{ marginTop: 10 }}
                      onClick={() => this.exportServices()}>
                      <Typography>Export</Typography>
                    </Button>
                  </Grid>
                </Grid>

                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Gear Up Services</TableCell>
                        <TableCell align="right">Unduplicated Number of GEAR UP Students who Participated in the Activity</TableCell>
                        <TableCell align="right">Sum Total of Hours that GEAR UP Students Participated in the Activity</TableCell>
                        <TableCell align="right">Total number of GEAR UP Parents who Participated in the Activity</TableCell>
                        <TableCell align="right">Sum Total of Hours that GEAR UP Parents who Participated in the Activity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {this.renderServices()}

                    </TableBody>
                  </Table>

                </Paper>
              </div>
            </div>

          </div>

        </React.Fragment>
      </div>
    );

  }

}


const mapStateToProps = (state, ownProps) => {
  return {
    userId: state.auth.userId,
    userName: state.auth.userName,
    token: state.auth.token,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportScreen);