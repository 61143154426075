import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { AppBar, Button, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
//import FileViewer from 'react-file-viewer';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import styles from "./style";
var AWS = require('aws-sdk');

var s3 = new AWS.S3({
  accessKeyId: 'AKIAICHRJYJL4LZYDYZQ',
  secretAccessKey: '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',
  region: 'us-east-1'
});

class PreviewScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      isLoading: true,
      evaluationDocumentId: 0,
      awsKey: '',
      fileType: '',
      fileName: '',
      filePath: '',
      fileContent: ''
    };
  }

  componentDidMount() {

    if (this.props.history.location.state !== undefined) {

      this.setState({
        evaluationDocumentId: this.props.history.location.state.evaluationDocumentId,
        awsKey: this.props.history.location.state.awsKey,
        fileType: this.props.history.location.state.fileType,
        fileName: this.props.history.location.state.fileName,
        filePath: this.props.history.location.state.filePath
      })

      fetch(this.props.history.location.state.filePath)
        .then(response => response.body.getReader().read()).then((file) => {
          let blob = new Blob([file], { type: this.props.history.location.state.fileType });

          this.setState({
            fileContent: blob
          })
        })

    }
  }

  onDeleteClick() {

    var props = this.props;
    var scope = this;

    var params = {
      Key: this.state.awsKey,
      Bucket: 'tassel.com'
    };

    s3.deleteObject(params, function (err, data) {
      if (err) {
        console.log(err, err.stack);
      }
      else {
        fetch('https://api.tassel.com/evaluation_document', {
          method: "DELETE",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'token': props.token
          },
          credentials: 'omit',
          body: JSON.stringify({
            evaluation_document_id: scope.state.evaluationDocumentId
          })
        })
          .then((response) => response.json())
          .then((responseJson) => {

            const location = {
              pathname: '/view_evaluation',
              state: {
                evaluationId: props.evaluationId,
                tabValue: 2
              }
            }

            props.history.push(location);

          })
          .catch((error) => {

          });
      }
    });
  }

  onPrintClick() {

    var url = window.URL.createObjectURL(this.state.fileContent);
    var tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', 'filename.pdf');
    tempLink.click();
  }

  onError(e) {
    console.log(e);
  }

  renderRightButton() {
    return (
      <div>
        <Button variant="contained" style={{ marginTop: 20, marginLeft: 10 }} target="_blank" href={this.state.filePath} download={true}
          color="primary" aria-label="Export" className={this.props.classes.margin}>
          <SaveAltIcon style={{ color: '#FFF' }} />
        </Button>
        <Button variant="contained" style={{ marginTop: 20, marginLeft: 10 }}
          color="primary" aria-label="Print" className={this.props.classes.margin}
          onClick={() => this.onPrintClick()}>
          <PrintIcon style={{ color: '#FFF' }} />
        </Button>
        {this.props.evaluationStatusId !== 4 ?
          <Button variant="contained" style={{ marginTop: 20, marginLeft: 10 }}
            onClick={() => this.onDeleteClick()}
            color="primary" aria-label="Delete" className={this.props.classes.margin}>
            <DeleteIcon className={this.props.classes.extendedIcon} style={{ color: '#FFF' }} />
          </Button> : null}
      </div>
    )
  }

  backClick() {
    const location = {
      pathname: '/view_evaluation',
      state: {
        evaluationId: + this.props.evaluationId,
        tabValue: 2
      }
    }

    this.props.history.push(location);
  }

  renderDocument() {
    if (this.state.fileType === "") {
      return (
        <Typography style={{ margin: 100, fontSize: 40, textAlign: 'center' }}>No Preview Available</Typography>
      )
    } else if (this.state.fileType === 'png' || this.state.fileType === 'jpg' ||
      this.state.fileType === 'jpeg' || this.state.fileType === 'gif' ||
      this.state.fileType === 'tiff') {
      return (
        <div style={{ marginTop: 40, marginLeft: 40, marginRight: 40, marginBottom: 40 }}>
          <img alt="" style={{ maxWidth: '100%', maxHeight: '100vh' }} src={this.state.filePath} />
        </div>
      )
    } else {
      return (
        {/*
        <FileViewer
          fileType={this.state.fileType}
          filePath={this.state.filePath}
          onError={this.onError} /> */}
      )
    }
  }

  render() {

    const { classes } = this.props;

    return (

      <div className={classes.root}>
        <React.Fragment>
          <AppBar position="sticky" elevation={0} className={classes.topBar}>
            <Toolbar>
              <Grid container>
                <Grid item xs={1}>
                  <IconButton style={{ marginTop: 20, textAlign: 'right', color: '#1F3545', fontSize: 28 }} onClick={() => this.backClick()}>
                    <ArrowBackIcon style={{ fontSize: 28 }} />
                  </IconButton>
                </Grid>
                <Grid item xs={7}>
                  <Typography style={{ fontSize: 28, marginTop: 20 }}>{this.state.fileName}</Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'right' }}>
                  {this.renderRightButton()}
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </React.Fragment>
        <Grid container spacing={24}>
          <Grid item xs={12} style={{ justifyContent: 'center', textAlign: 'center', width: 500 }}>

            {this.renderDocument()}

          </Grid>
        </Grid>


        <iframe id="printf" title="print" style={{ display: 'none' }}></iframe>

      </div>

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRightId: state.auth.userRightId,
    districtId: state.district.districtId,
    evaluationId: state.assessment.evaluationId,
    evaluationStatusId: state.assessment.evaluationStatusId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PreviewScreen));