import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT } from "../actions";
require('es6-promise').polyfill();
require('isomorphic-fetch');


export const loginRequest = () => {
    return {
        type: LOGIN_REQUEST
    };
};

export const loginSuccess = (userId, username, password, firstName, lastName, token, disabled, isAdmin) => {
    return {
        type: LOGIN_SUCCESS,
        userId: userId,
        username: username,
        password: password,
        firstName: firstName,
        lastName: lastName,
        token: token,
        disabled: disabled,
        isAdmin: isAdmin
    }
};

export const login = (username, password) => {
    return (dispatch) => {

        if (!username || !password) {
            dispatch(loginFailure(true));
            return;
        }

        fetch(process.env.REACT_APP_API_URL + '/authenticate', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_name: username,
                password: password,
            })
        })
            .then(response => {
                return response.json()
            })
            .then((responseJson) => {
                if (responseJson.authenticated === true) {
                    dispatch(loginSuccess(responseJson.user_id, username, password, responseJson.first_name, responseJson.last_name, responseJson.token, responseJson.disabled, responseJson.is_admin));
                } else {
                    dispatch(loginFailure(true));
                }
            })
            .catch((e) => {
                console.warn(e);
                dispatch(loginFailure(true));
            });
    };
};

export const loginFailure = (hasError) => {
    return {
        type: LOGIN_FAILURE,
        hasError: hasError
    };
};

export const logout = () => {
    return {
        type: LOGOUT
    };
};