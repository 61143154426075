import React from 'react';
// import classNames from 'classnames';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {
  AppBar, Toolbar
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import GearIcon from '../../../assets/launcher.png';
import { logout } from '../../../redux/actions/auth';
import './style.css';


class HeaderBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      route: 'HeaderBar',
      anchorEl: false,
      anchorElLogout: false
    }
  }

  onLogout() {
    this.props.onLogout();
    this.props.history.push('/');
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: true
    })
  }

  handleClickLogout = (event) => {
    this.setState({
      anchorElLogout: true
    })
  }

  handleClose = () => {
    this.setState({
      anchorEl: false,
      anchorElLogout: false
    })
  }

  onButtonClick() {

  }

  render() {

    const { classes, ...other } = this.props;

    return (
      <div className="root">
        <AppBar style={{ backgroundColor: '#C9322D' }} position="fixed" >
          <Toolbar>
            <IconButton edge="start" className="menuButton" onClick={this.handleClick}>
              <img src={GearIcon} alt="Logo" height={40} />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={this.state.anchorEl}
              open={this.state.anchorEl}
              getContentAnchorEl={null}
              style={{ marginTop: -50 }}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}

              onClose={this.handleClose}
            >
              <MenuItem onClick={this.handleClose} style={{ fontFamily: 'Josefin Sans' }} component={props => <Link to="/activities" {...props} />}>Manage Activities</MenuItem>
              <MenuItem onClick={this.handleClose} style={{ fontFamily: 'Josefin Sans' }} component={props => <Link to="/users" {...props} />}>Manage Users</MenuItem>
              <MenuItem onClick={this.handleClose} style={{ fontFamily: 'Josefin Sans' }} component={props => <Link to="/students" {...props} />}>Manage Students</MenuItem>
              <MenuItem onClick={this.handleClose} style={{ fontFamily: 'Josefin Sans' }} component={props => <Link to="/reports" {...props} />}>Reports</MenuItem>
              <MenuItem onClick={this.handleClose} style={{ fontFamily: 'Josefin Sans' }} component={props => <Link to="/categories" {...props} />}>Categories</MenuItem>
              <MenuItem onClick={() => this.onLogout()} style={{ fontFamily: 'Josefin Sans' }}>Logout</MenuItem>
            </Menu>

            <h1 className="admin" style={{ fontSize: 25, paddingTop: 7, marginLeft: 5, letterSpacing: 2, fontWeight: 200 }}> Admin</h1>

            <AccountCircle style={{ marginLeft: '70%' }} /> <br></br>



            <br></br>
            <div style={{ fontFamily: 'Josefin Sans', paddingTop: 6, fontSize: 18 }}>{this.props.firstName} {this.props.lastName}</div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

// HeaderBar.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    firstName: state.auth.firstName,
    lastName: state.auth.lastName,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => {
      dispatch(logout());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderBar));